import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Input, Checkbox, Select } from "antd";
const { Item: FormItem } = Form;
import moment from "moment";
import { hasPermission } from "utils/permissions";
import gameStreamConfigurationType from "types/game/streamConfiguration.type";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";
import { HTTP_AND_WS_URL_REGEX } from "constants/regex.constants";
import { DATE_TIME_FORMAT } from "constants/date.constants";


const NanoAndFlussonicForm = ({
	setFieldsValue,
	streamProvider,
	streamConfiguration,
	isLanguagesLoading,
	languages,
}) => {
	const { t } = useTranslation();

	const [collapseActiveKeys, setCollapseActiveKeys] = useState([]);
	const [config, setConfig] = useState([]);

	const setFormInitialData = () => {
		const { streams, ...data } = streamConfiguration;
		data.streamProvider = streamConfiguration.streamProvider;
		const streamData = (
			streamConfiguration.streams && streamConfiguration.streams.length > 0
				? streamConfiguration.streams.map((s) => ({ ...s, languageCode: s.languageCode || "" }))
				: [
					{
						[(streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS ? "name" : "url")]: "",
						languageCode: "",
						securityEnabled: true
					}
				]
		)
		data[`streams-${streamConfiguration.streamProvider}`] = streamData;

		setFieldsValue(data);
		setConfig(streamData.map(s => languages[s.languageCode.toLowerCase()]));
	};

	/** Set Initial Config state */
	useEffect(() => {
		setFormInitialData();
	}, [streamConfiguration, languages]);

	useEffect(() => {
		if (!streamConfiguration.streams || streamConfiguration.streams.length === 0 || streamConfiguration.streamProvider !== streamProvider) {
			setFieldsValue({
				[`streams-${streamProvider}`]: [{ [`${streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS ? "name" : "url"}`]: "", languageCode: "", securityEnabled: true }]
			});
			setCollapseActiveKeys([0]);
			setConfig([""]);
		} else if (streamProvider === streamConfiguration.streamProvider) {
			setFormInitialData(true);
		}
	}, [streamProvider, languages]);



	return (
		<Form.List name={`streams-${streamProvider}`}>
			{(fields, { add, remove }) => {
				return (
					<Fragment>
						{
							fields.map((field, index) => {
								const stream = streamConfiguration?.streams?.[index] ?? {};
								return (
									<Row gutter={[16, 0]} key={index}>
										<Col span={24}>
											<div className="dashboard-stream-config-item">
												<div
													className={"dashboard-stream-config-item-header" + (collapseActiveKeys.includes(index) ? " dashboard-stream-config-item-header-active" : "")}
													onClick={() => {
														if (collapseActiveKeys.includes(index)) {
															return setCollapseActiveKeys(collapseActiveKeys.filter(item => item !== index))
														}
														setCollapseActiveKeys(collapseActiveKeys.concat([index]))
													}}
												>
													<div className="dashboard-stream-config-item-header-title">
														<i className="icon-down" />
														<span>{config[index] || t("common.none")}</span>
													</div>
													{
														(hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })) && fields.length > 1
															? (
																<div
																	className={
																		"dashboard-stream-config-item-header-delete" +
																		(
																			fields.length <= 1
																				? " dashboard-stream-config-delete-disabled"
																				: ""
																		)
																	}
																	onClick={e => {
																		e.preventDefault();
																		e.stopPropagation();
																		fields.length > 1 && remove(index);
																		setConfig(config.filter((s, i) => i !== index));
																		setCollapseActiveKeys(collapseActiveKeys.filter(k => k !== index).map(k => k > index ? k - 1 : k))
																	}}
																>
																	<i className="icon-remove" />
																</div>
															)
															: null
													}
												</div>
												<div className="dashboard-stream-config-item-content">
													<div className="dashboard-stream-config-item-content-inner">
														<Row gutter={[16, 0]}>
															<Col xs={24} sm={12}>
																<FormItem
																	label={
																		t(
																			streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
																				? "pages.dashboard.games.streamConfigs.name"
																				: "pages.dashboard.games.streamConfigs.url"
																		)
																	}
																	name={streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS ? [index, "name"] : [index, "url"]}
																	rules={
																		streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
																			? [
																				{
																					required: true,
																					message: t("validation.field_required"),
																				},
																			]
																			: [
																				{ required: true, whitespace: true, message: t('validation.field_required') },
																				{ pattern: HTTP_AND_WS_URL_REGEX, message: t('validation.url_format') }
																			]
																	}
																>
																	<Input
																		placeholder={
																			t("common.enter") + " " +
																			t(
																				streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
																					? "pages.dashboard.games.streamConfigs.name"
																					: "pages.dashboard.games.streamConfigs.url"
																			)
																		}
																		disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })}
																	/>
																</FormItem>
															</Col>
															<Col xs={24} sm={12}>
																<FormItem
																	label={t("pages.dashboard.games.streamConfigs.languageCode")}
																	name={[index, "languageCode"]}
																>
																	<Select
																		placeholder={t("common.enter") + " " + t("pages.dashboard.games.streamConfigs.languageCode")}
																		loading={isLanguagesLoading}
																		disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })}
																		suffixIcon={<i className="icon-down" />}
																		getPopupContainer={() => document.body}
																		onChange={(_, data) => setConfig(config.map((s, i) => i === index ? languages[data.key] : s))}
																	>
																		<Select.Option value="">
																			{t("common.none")}
																		</Select.Option>
																		{
																			Object.keys(languages)
																				.map((lang) => (
																					<Select.Option
																						key={lang}
																						value={lang.toUpperCase()}
																					>
																						{languages[lang]}
																					</Select.Option>
																				)
																				)
																		}
																	</Select>
																</FormItem>
															</Col>
															<Col span={24}>
																<div
																	className="inline-form-checkbox"
																>
																	<label>
																		{t("pages.dashboard.games.streamConfigs.streamSecurity")}
																	</label>
																	<FormItem
																		className="inline-form-item-control form-item-without-margin"
																		name={[index, "securityEnabled"]}
																		valuePropName="checked"
																	>
																		<Checkbox
																			disabled={!hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })}
																		/>
																	</FormItem>
																</div>
															</Col>
															{
																streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
																	&&
																	stream.securityEnabled
																	&&
																	moment(stream.lastUpdateDate ?? "").isValid()
																	? (
																		<Col span={12}>
																			<div
																				className="inline-form-item dashboard-stream-config-item-text"
																			>
																				<label>{t("pages.dashboard.games.streamConfigs.lastUpdateDate") + ": "}</label>
																				<h4>
																					{
																						moment.utc(stream.lastUpdateDate ?? "")
																							.local().format(DATE_TIME_FORMAT)
																					}
																				</h4>
																			</div>
																		</Col>
																	)
																	: null
															}
															{
																streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
																	&&
																	stream.securityEnabled
																	&& !!stream.lastMessage
																	? (
																		<Col span={12}>
																			<div
																				className="inline-form-item dashboard-stream-config-item-text"
																			>
																				<label>{t("pages.dashboard.games.streamConfigs.lastMessage") + ": "} </label>
																				<h4 className="dashboard-stream-config-item-text-bold">{stream.lastMessage}</h4>
																			</div>
																		</Col>
																	)
																	: null
															}
														</Row>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								)
							})
						}
						{
							fields.length < 20 && hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY })
								? (
									<div className="dashboard-stream-config-add">
										<Button
											type="secondary"
											onClick={() => {
												add(
													{
														[streamProvider === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS ? "name" : "url"]: "",
														languageCode: "",
														securityEnabled: true,
													}
												);
												setConfig(config.concat([""]));
												setCollapseActiveKeys(collapseActiveKeys.concat([fields.length]))
											}}
										>
											{t("pages.dashboard.games.streamConfigs.addNew")}
										</Button>
									</div>
								)
								: null
						}
					</Fragment>
				)
			}}
		</Form.List>
	)
};

NanoAndFlussonicForm.propTypes = {
	/** React property, set Form Fields value */
	setFieldsValue: PropTypes.func,
	/** Redux action to get game stream configuration*/
	getGameStreamConfiguration: PropTypes.func,
	/** React property */
	streamProvider: PropTypes.number,
	/** Redux state, represents the general info of current editing game  */
	streamConfiguration: gameStreamConfigurationType,
	/** Redux state property, is true when loading partner languages */
	isLanguagesLoading: PropTypes.bool,
	/** Redux state property, represents the object of loaded partner languages */
	languages: PropTypes.object,
	/** Redux action to get all languages for partner */
	getPartnerAvailableLanguages: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		streamConfiguration: state.games.editingGame.streamConfiguration,
		isLanguagesLoading: state.partner.language.isAvailableLoading,
		languages: state.partner.language.availableLanguages,
	};
};

export default connect(mapStateToProps, null)(NanoAndFlussonicForm);