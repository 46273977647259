//eslint-disable-next-line
export const URL_REGEX = /^http(s)?:\/\/([\w-]+.)+[\w-]+(\/[\w\- .\/?%&=])?$/;

export const HTTP_AND_WS_URL_REGEX = /^(http[s]?|ws[s]?):\/\/([\w-]+\.)+[\w-]+(:\d+)?(\/[\w\-\/.?%&=+#]*)?$/;

export const EMAIL_REGEX = /^([a-zA-Z0-9.\-_]+)@[a-zA-Z0-9.\-_]+\.[a-zA-Z]{2,5}$/;

export const TEL_REGEX = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;

export const IP_V4_ADDRESS_REGEX = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const IP_V6_ADDRESS_REGEX = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

export const DYNAMIC_PATH_ID_REGEX = /(:id)$/;
export const DYNAMIC_PATH_NAME_REGEX = /(:name)$/;
export const DYNAMIC_PATH_GAME_CATEGORY_REGEX = /(:gameCategory)+/;

export const ADMIN_WS_CHECKING_REGEX = new RegExp("^" + import.meta.env.SYSTEM_SIGNALR_URL_ADMIN);
export const CASHIER_WS_CHECKING_REGEX = new RegExp("^" + import.meta.env.SYSTEM_SIGNALR_URL_CASHIER);
