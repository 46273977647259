import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Form, Row, Col, DatePicker, Input } from "antd";
import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";
import { DATE_FORMAT } from "constants/date.constants";
import useAutosuggestion from "hooks/useAutosuggestion";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { getSSBTs, setSSBTsFiltersAction } from "store/actions/dashboard/ssbt/ssbt.action.js";
import { GAME_CHANNEL } from "constants/game.constants.js";
const { Item: FormItem } = Form;

const Filters = () => {
	const filters = useSelector((state) => state.ssbts.filters);
	const globalPartnerId = useSelector((state) => state.partner.globalPartnerId);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const gameNames = useAutosuggestion(AUTOSUGGESTION_TYPE.GAME_NAMES, [globalPartnerId], {
		disableAction: () => !globalPartnerId,
		actionArguments: [GAME_CHANNEL.RETAIL]
	});

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	const filtersList = [
		{ name: "nameOrId", title: t("pages.dashboard.ssbt.ssbt_name_or_ID") },
		{
			name: "gameId",
			title: t("pages.dashboard.ssbt.games"),
			values: gameNames.map((g) => ({ title: g.value, value: g.key }))
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "updateDate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper
			filtersName="apiKeys"
			loadFn={() => dispatch(getSSBTs())}
			setFiltersFn={(filters) => dispatch(setSSBTsFiltersAction(filters))}
			filters={filters}
			formFieldsConfigs={formFieldsConfigs}
			filtersList={filtersList}
		>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.ssbt.ssbt_name_or_ID")} name="nameOrId">
							<Input placeholder={`${t("common.start_typing")} ${t("pages.dashboard.ssbt.ssbt_name_or_ID")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.ssbt.games")} name="gameId">
							<SearchableSelect
								items={gameNames}
								valueProp={(item) => item.key}
								textProp={(item) => item.value}
								renderText={(item) => item.value}
								addNone={true}
								noneText={t("common.all")}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={DATE_FORMAT}
								disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="updateDate">
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={DATE_FORMAT}
								disabledDate={(d) => !d || d.isAfter(moment().endOf("day"))}
							/>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

export default Filters;
