import { message } from "antd";
import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_STATE } from "constants/response.constants";

import { setGamesActionBefore, setGamesActionFinished, setGameSaveActionBefore, setGameSaveActionFinished } from "./games.action";

import { SET_GAME_STREAM_CONFIGURATION } from "store/actionTypes";

import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

const setGameStreamConfiguration = (configuration) => ({
	type: SET_GAME_STREAM_CONFIGURATION,
	payload: { configuration }
});

export const getGameStreamConfiguration = (id) => {
	return (dispatch) => {
		dispatch(setGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_GET_STREAM_CONFIGURATION}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: configuration } }) => {
				dispatch(
					setGameStreamConfiguration({
						...configuration.streamConfiguration,
						streamProvider: configuration.streamProvider
					})
				);
				dispatch(setGamesActionFinished());
			})
			.catch((ex) => {
				dispatch(setGamesActionFinished());
			});
	};
};

export const saveGameStreamConfiguration = (id, configuration) => {
	return (dispatch) => {
		dispatch(setGameSaveActionBefore());
		const params = {};
		params.id = id;
		params.streamProvider = configuration.streamProvider;

		switch (configuration.streamProvider) {
			case GAME_STREAM_CONFIGURATION_TYPE.WOWZA:
				params.wowza = configuration;
				delete params.wowza.streamProvider;
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC:
				params.flussonic = configuration;
				delete params.flussonic.streamProvider;
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS:
				params.nanoCosmos = configuration;
				delete params.nanoCosmos.streamProvider;
				break;
			case GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER:
				params.ovenPlayer = configuration;
				delete params.ovenPlayer.streamProvider;
				break;
			default:
				break;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_SAVE_STREAM_CONFIGURATION}`,
			method: Methods.POST,
			data: params
		})
			.then(({ data: { message: msg, status, value: configuration } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					dispatch(
						setGameStreamConfiguration({
							...configuration.streamConfiguration,
							streamProvider: configuration.streamProvider
						})
					);
				}
				dispatch(setGameSaveActionFinished());
			})
			.catch((ex) => {
				dispatch(setGameSaveActionFinished());
			});
	};
};
