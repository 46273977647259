import Breadcrumbs from "components/common/breadcrumbs/index.jsx";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paths from "constants/path.constants.js";
import { useEffect } from "react";
import { getEditingSSBTGames } from "store/actions/dashboard/ssbt/ssbt.action.js";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import { hasPermission } from "utils/permissions.js";
import GamesComponent from "components/dashboard/ssbt/edit/sections/games/index.jsx";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";

const SSBTEditComponent = () => {
	const dispatch = useDispatch();
	const routeParams = useParams();
	const { t } = useTranslation();

	useEffect(() => {
		if (routeParams.id) {
			dispatch(getEditingSSBTGames(routeParams.id));
		}
	}, [routeParams.id]);

	return (
		<div className="dashboard-section">
			<Breadcrumbs items={[{ title: t("pages.dashboard.menu.ssbt"), path: Paths.DASHBOARD_SSBT }, { title: t("common.edit") }]} />
			<Tabs
				animated={false}
				items={[
					hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.VIEW }) && {
						key: "1",
						label: <span>{t("pages.dashboard.ssbt.tabs.games")}</span>,
						children: <GamesComponent />
					}
				]}
			/>
		</div>
	);
};

export default SSBTEditComponent;
