import { useState, useEffect, Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Select, Checkbox, Tooltip, Table, Input } from "antd";
const { Item: FormItem } = Form;
const { Option } = Select;
import { SearchOutlined } from "@ant-design/icons";

import SearchableSelect from "components/common/searchableSelect";

import { addApiKeyGames } from "store/actions/dashboard/online/apikeys/games.action";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action";

import { flagsToBinary, getGamesFromConfig } from "utils/common";
import { isFormChanged } from "utils/form";

import apiKeyGameType from "types/apiKey/apiKeyGame.type";
import gameType from "types/game/game.type";

import { GAME_CATEGORY, GAME_CHANNEL, GAME_LABEL_TYPE, REDUX_EDITING_API_KEY_GAME_FIELD } from "constants/game.constants";

/** Apikey Game Adding Popup Component */
const GameAddComponent = ({ initialGameList, isSaving, addApiKeyGames, onSuccess, getGames, onClose, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [formInstance] = Form.useForm();
	const { getFieldValue, getFieldsValue, setFieldValue, setFieldsValue } = formInstance;

	const [selectedGameTypesAndIds, setSelectedGameTypesAndIds] = useState([]);
	const [formGames, setFormGames] = useState([]);
	const [search, setSearch] = useState("");
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Get all games */
	useEffect(() => {
		getGames(gameCategory);
	}, [gameCategory]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GameAddComponent
	 */
	const handleForm = () => {
		const fields = getAllValue();
		const games = Object.values(fields).reduce((acc, game) => {
			if (!game.checked) {
				return acc;
			}

			const gameLabelArr = [];

			if (game.isNew) {
				gameLabelArr.push(GAME_LABEL_TYPE.NEW);
			}
			if (game.isTest) {
				gameLabelArr.push(GAME_LABEL_TYPE.TEST);
			}
			const gameLabel = flagsToBinary(gameLabelArr);
			acc.push({
				gameId: game.id,
				gameLabel: gameLabel || null
			});

			return acc;
		}, []);

		addApiKeyGames(
			{
				id: routeParams.id,
				games
			},
			gameCategory,
			onSuccess
		);
	};

	useEffect(() => {
		if (!gameCategory || !initialGameList.length) {
			return;
		}

		const initial = initialGameList.map((game) => ({
			id: game.id,
			name: game.name,
			gameType: game.type,
			checked: false,
			isNew: false,
			isTest: false
		}));

		setFormGames(initial);
	}, [initialGameList, gameCategory, setFormGames]);

	const getAllValue = () => {
		return formGames.reduce((acc, game) => {
			const values = getFieldValue([game.id]);
			if (values) {
				acc[game.id] = values;
			}
			return acc;
		}, {});
	};

	const handleFormChange = () => {
		const formValues = getAllValue();
		const gameIds = Object.keys(getAllValue());
		const filtered = formGames.filter((formGame) => gameIds.includes(formGame.id));
		const generatedInitial = filtered.reduce((acc, game) => {
			acc[game.id] = game;
			return acc;
		}, {});
		setIsFormTouched(isFormChanged({ ...formValues }, generatedInitial));
	};

	const dataSource = useMemo(() => {
		return formGames.filter((game) => {
			return !search || game.name?.toLowerCase().includes(search.toLowerCase());
		});
	}, [formGames, search]);

	const columns = [
		{
			title: <span className="vs--pl-32">{t("pages.dashboard.games.game")}</span>,
			dataIndex: "column1",
			render: (_, record, index) => {
				return (
					<div className="vs--flex vs--justify-start vs--align-center">
						<Form.Item shouldUpdate noStyle>
							{() => {
								const fields = getAllValue();
								const checked = fields?.[record.id]?.checked ?? false;
								const isDisabled = selectedGameTypesAndIds.find((selectedGameTypeAndId) => {
									return record.id !== selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType;
								});
								return (
									<Checkbox
										disabled={isDisabled}
										checked={checked}
										onChange={(e) => {
											const newField = { ...fields };
											const newChecked = !checked;
											newField[record.id] = { ...record, checked: newChecked, isNew: false, isTest: false };
											setFieldsValue(newField);
											if (newChecked) {
												setSelectedGameTypesAndIds((prev) => [...prev, { id: record.id, gameType: record.gameType }]);
											} else {
												setSelectedGameTypesAndIds((prev) =>
													prev.filter((selectedGameTypeAndId) => {
														return !(record.id === selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType);
													})
												);
											}
											handleFormChange();
										}}
									/>
								);
							}}
						</Form.Item>
						<span className="vs--ml-16">{record.name}</span>
					</div>
				);
			}
		},
		{
			title: `${t("pages.dashboard.apikeys.new")} ${t("pages.dashboard.games.game")}`,
			dataIndex: "column2",
			align: "center",
			render: (_, record) => {
				return (
					<Form.Item shouldUpdate noStyle>
						{() => {
							const game = getFieldValue([record.id]);
							const isGameChecked = game?.checked ?? false;
							const isChecked = game?.isNew ?? false;
							return (
								<Checkbox
									disabled={!isGameChecked}
									checked={isChecked}
									onChange={(e) => {
										const fields = getAllValue();
										const newField = { ...fields };
										newField[record.id] = { ...game, isNew: !isChecked };
										setFieldsValue(newField);
										handleFormChange();
									}}
								/>
							);
						}}
					</Form.Item>
				);
			}
		},
		{
			title: t("pages.dashboard.games.is_test_game"),
			dataIndex: "column3",
			align: "center",
			render: (_, record) => {
				return (
					<Form.Item shouldUpdate noStyle>
						{() => {
							const game = getFieldValue([record.id]);
							const isGameChecked = game?.checked ?? false;
							const isChecked = game?.isTest ?? false;
							return (
								<Checkbox
									disabled={!isGameChecked}
									checked={isChecked}
									onChange={(e) => {
										const fields = getAllValue();
										const newField = { ...fields };
										newField[record.id] = { ...game, isTest: !isChecked };
										setFieldsValue(newField);
										handleFormChange();
									}}
								/>
							);
						}}
					</Form.Item>
				);
			}
		}
	];

	return (
		<Modal
			open={true}
			title={
				<Fragment>
					<span>{t("pages.dashboard.apikeys.add_new_game")}</span>
					<Tooltip
						title={
							<div>
								<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.apikeys.game_add_info") }}></span>
							</div>
						}
						getPopupContainer={() => document.body}
					>
						<i className="icon-info" />
					</Tooltip>
				</Fragment>
			}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={800}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched }}
			centered
			className="apikey-add-NewGame-modal"
		>
			<Form
				className="dashboard-form apikey-edit-addNewGame"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{}}
			>
				<div className="vs--mb-8">
					<Input prefix={<SearchOutlined />} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>
				<div>
					<Table rowKey="id" columns={columns} dataSource={dataSource} pagination={false} />
				</div>
			</Form>
		</Modal>
	);
};

/** GameAddComponent propTypes
 * PropTypes
 */
GameAddComponent.propTypes = {
	/** React property function, need run for every success game add operation */
	onSuccess: PropTypes.func,
	/** Redux state property, is true when adding game for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add game to api key*/
	addApiKeyGames: PropTypes.func,
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY)),
	/** Redux state property, represents the array of games  */
	initialGameList: PropTypes.arrayOf(apiKeyGameType)
};

const mapDispatchToProps = (dispatch) => ({
	addApiKeyGames: (gamesData, gameCategory, onSuccess) => {
		dispatch(addApiKeyGames(gamesData, gameCategory, onSuccess));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory, GAME_CHANNEL.ONLINE));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		isSaving: state.apikeys.isSaving
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameAddComponent);
