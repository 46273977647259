import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Table from "components/common/table";
import Flag from "components/common/flag";
import NumericInput from "components/common/numericInput";
import { Form, Button, Switch, Tooltip } from "antd";
import { getPenaltyShootTeamsAndRatings, savePenaltyShootTeamsAndRatings } from "store/actions/dashboard/virtuals/games/probabilities.action";
import { hasPermission } from "utils/permissions";
import { isFormChanged } from "utils/form";
import systemFootballTeams from "systemData/footballTeams";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_TEAM_CONFIGS, PENALTY_SHOOTOUT_TEAMS_AND_RATINGS_VALIDATION_COEFFICIENT, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import gameGeneralInfoType from "types/game/generalInfo.type";
import { isNullish } from "utils/common";
import AddTeams from "./addTeams";

const { Item: FormItem } = Form;
const [MIN_RAITING, MAX_RAITING] = PENALTY_SHOOTOUT_TEAMS_AND_RATINGS_VALIDATION_COEFFICIENT;
const { MIN_ENABLED_COUNT, MAX_TEAMS_COUNT } = GAME_TEAM_CONFIGS[SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value]

const TeamsAndRatings = ({ getPenaltyShootTeamsAndRatings, savePenaltyShootTeamsAndRatings, isSaving, generalInfo, penaltyTeams, teamFormat }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { setFieldsValue, validateFields, getFieldValue, getFieldsValue } = formInstance;
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME_PROBABILITIES, action: PERMISSION_ACTION.MODIFY });
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [showAddPopup, setShowAddPopup] = useState(false)
	const [disabledForQuantity, setDisabledForQuantity] = useState(false)

	const toLineKey = (prefix, record) => `${prefix}_${record.countryCode}_${record.totalPoints}`;
	const getAllEnabledTeamsCount = (srcData) => {
		const fields = srcData ?? getFieldsValue()
		const allKeys = Object.keys(fields);
		const keysWithEnabled = allKeys.filter(key => key.includes("enabled"))
		const allEnabled = keysWithEnabled.filter(key => fields[key])
		return allEnabled.length
	}

	const mapDataForForm = (penaltyTeamsArg) => {
		return penaltyTeamsArg.reduce((acc, team) => {
			acc[toLineKey("totalPoints", team)] = isNullish(team.totalPoints) ? "0" : `${team.totalPoints}`;
			acc[toLineKey("enabled", team)] = team.enabled ?? false;
			return acc;
		}, {});
	};

	/** Fires when form submitted
	 * @function
	 * @memberOf TeamsAndRatings
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				savePenaltyShootTeamsAndRatings(
					generalInfo.id,
					penaltyTeams.map((penaltyTeam) => {
						return {
							...penaltyTeam,
							enabled: Boolean(data[toLineKey("enabled", penaltyTeam)]),
							totalPoints: Number(data[toLineKey("totalPoints", penaltyTeam)])
						};
					})
				);
				setIsFormTouched(false);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	/** Check is form changed
	 * @function
	 * @param {object} formValues - form current values
	 * @returns {boolean}
	 * @memberOf function
	 */
	const formChanged = (formValues) => {
		return isFormChanged(formValues, mapDataForForm([...penaltyTeams]));
	};

	const columns = [
		{
			title: t("pages.dashboard.games.probabilities.team"),
			dataIndex: "enabled",
			render: (_, record) => {
				const name = toLineKey("enabled", record);
				const needDisableForQuantity = disabledForQuantity && getFieldValue(name)
				return (
					<div className="vs--flex vs--align-center">
						<Tooltip
							title={
								needDisableForQuantity
									? t("validation.must_be_more").replace("%X%", `${MIN_ENABLED_COUNT} teams`)
									: null
							}
						>
							<span>
								<FormItem
									className="inline-form-item-control table-form-control"
									name={name}
									valuePropName="checked"
									noStyle
								>
									<Switch
										className="vs--cursor-pointer"
										disabled={isDisabled || needDisableForQuantity}
										onChange={() => {
											const count = getAllEnabledTeamsCount()
											setDisabledForQuantity(count < MIN_ENABLED_COUNT)
										}}
									/>
								</FormItem>
							</span>
						</Tooltip>
						<Flag className="vs--ml-8" code={record.countryCode} gameType={generalInfo.type} />
						<span>{`${record.countryCode} - ${record.countryName}`}</span>
					</div>
				);
			},
			sorter: false,
			copy: false
		},
		{
			title: t("pages.dashboard.games.probabilities.team_ratings"),
			dataIndex: "totalPoints",
			render: (_, record) => {
				const name = toLineKey("totalPoints", record);
				return (
					<FormItem
						className="inline-form-item-control team_ratings_probablities"
						name={name}
						rules={[
							{ required: true, whitespace: true, message: t("validation.field_required") },
							() => ({
								message: t("validation.must_be_between").replace("%X%", MIN_RAITING).replace("%Y%", MAX_RAITING),
								validator(rule, value) {
									const numericValue = Number(value);
									if (numericValue < MIN_RAITING || MAX_RAITING < numericValue) {
										return Promise.reject();
									}
									return Promise.resolve();
								}
							})
						]}
						validateFirst
					>
						<NumericInput disabled={isDisabled} />
					</FormItem>
				);
			},
			sorter: false
		}
	];

	useEffect(() => {
		getPenaltyShootTeamsAndRatings(routeParams.id);
	}, []);

	useEffect(() => {
		if (!penaltyTeams || !penaltyTeams.length) {
			return;
		}
		const dataForForm = mapDataForForm([...penaltyTeams]);
		setFieldsValue(dataForForm);
		const count = getAllEnabledTeamsCount(dataForForm)
		setDisabledForQuantity(count < MIN_ENABLED_COUNT)
	}, [penaltyTeams]);

	return (
		<div className="dashboard-section table-section">
			<div className="vs--flex vs--justify-end vs--align-center vs--mb-16">
				<Button onClick={() => setShowAddPopup(true)} type="primary" disabled={penaltyTeams?.length >= MAX_TEAMS_COUNT}>
					{t("pages.dashboard.games.probabilities.add_team")}
				</Button>
			</div>
			<Form initialValues={undefined} form={formInstance} layout="vertical" colon={false} disabled={isDisabled} onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}>
				<Table loading={false} columns={columns} data={penaltyTeams} pagination={false} />
				{showAddPopup && (
					<AddTeams
						id={routeParams.id}
						teamFormat={teamFormat}
						onClose={() => {
							setShowAddPopup(false);
						}}
						onSuccess={() => {
							setShowAddPopup(false);
							getPenaltyShootTeamsAndRatings(routeParams.id);
						}}
						existingTeams={penaltyTeams ?? []}
					/>
				)}
				{
					<div className="vs--flex vs--justify-end vs--align-center">
						<Tooltip
							title={disabledForQuantity ? t("validation.must_be_more").replace("%X%", `${MIN_ENABLED_COUNT} teams`) : null}
						>
							<FormItem className="button-container">
								<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={isDisabled || disabledForQuantity || !isFormTouched}>
									<span>{t("common.save")}</span>
								</Button>
							</FormItem>
						</Tooltip>
					</div>
				}
			</Form>
		</div>
	);
};

TeamsAndRatings.propTypes = {
	/** Redux action to get Penalty shoot teams and Ratings */
	getPenaltyShootTeamsAndRatings: PropTypes.func,
	/** Redux state, represents the general info of current editing game */
	generalInfo: gameGeneralInfoType,
	/** Redux state property, is true when Penalty shoot teams and Ratings is loading */
	isLoading: PropTypes.bool,
	/** Redux state property, is true when Penalty shoot teams and Ratings is saving */
	isSaving: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => ({
	getPenaltyShootTeamsAndRatings: (id) => {
		dispatch(getPenaltyShootTeamsAndRatings(id));
	},
	savePenaltyShootTeamsAndRatings: (id, teams) => {
		dispatch(savePenaltyShootTeamsAndRatings(id, teams));
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.games.editingGame.generalInfo,
		isLoading: state.games.isLoading,
		isSaving: state.games.isSaving,
		penaltyTeams: state.games.editingGame.probabilities.penaltyTeams.teams,
		teamFormat: state.games.editingGame.probabilities.penaltyTeams.teamFormat
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsAndRatings);
