import { Component } from "react";

import { Navigate } from "react-router-dom";
import { hasPermission, hasOneOfPermissions } from "utils/permissions";

/** Hocs which allows access to page only if user
 * @function
 * @param {Component} WrappedComponent - Component to add functionality
 * @param {string/Array} permission - permission to check
 * @param {string} redirectURL - url to redirect when don't have permission
 */
const WithPermission = (WrappedComponent, permission, redirectURL) => {
	const canOpenPage = (permission) =>
		!permission || (!Array.isArray(permission) && hasPermission(permission)) || (Array.isArray(permission) && hasOneOfPermissions(permission));

	class WithPermission extends Component {
		render() {
			if (!canOpenPage(permission)) {
				return <Navigate to={redirectURL} replace />;
			}

			return <WrappedComponent {...this.props} />;
		}
	}

	WithPermission.displayName = `WithPermission(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;
	return WithPermission;
};

export default WithPermission;
