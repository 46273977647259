import { Fragment, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Row, Col, Select, DatePicker } from "antd";
import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";
import { setGameFilters, getGames } from "store/actions/dashboard/virtuals/games/games.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import {
	FILTER_STATUS,
	FILTER_TEST_STATE,
	FILTER_GAME_SPECIFICATION_TYPE,
	FILTER_GAME_CHANNEL_TYPE
} from "constants/filter.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { GAME_CATEGORY } from "constants/game.constants";
import { getGamesFromConfig } from "utils/common";
const { Item: FormItem } = Form;

/** Games Page Filters Component */
const Filters = ({ setGameFilters, getGames, filters, globalPartnerId, gameCategory }) => {
	const { t } = useTranslation();
	const filtersName = gameCategory === GAME_CATEGORY.SCHEDULED ? "virtualScheduledGames" : "virtualInstantGames";
	const gameNames = useAutosuggestion(AUTOSUGGESTION_TYPE.GAME_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [
				{ name: "date", keepTime: false },
				{ name: "lastUpdate", keepTime: false }
			]
		};
	}, []);

	const filtersList = [
		{ name: "nameOrId", title: t("pages.dashboard.games.game_name_or_id") },
		{
			name: "gameType",
			title: t("pages.dashboard.games.type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				getGamesFromConfig(gameCategory).map((g) => ({
					title: t(`common.${g.type}`),
					value: g.value
				}))
			)
		},
		...(gameCategory === GAME_CATEGORY.SCHEDULED
			? [
					{
						name: "gameChannel",
						title: t("pages.dashboard.games.channel"),
						values: [
							{ title: t("common.all"), value: FILTER_GAME_CHANNEL_TYPE.ALL },
							{ title: t("pages.dashboard.games.channels.online"), value: FILTER_GAME_CHANNEL_TYPE.ONLINE },
							{ title: t("pages.dashboard.games.channels.retail"), value: FILTER_GAME_CHANNEL_TYPE.RETAIL },
						]
					},
					{
						name: "specificationType",
						title: t("pages.dashboard.games.specification"),
						values: [
							{ title: t("common.all"), value: FILTER_GAME_SPECIFICATION_TYPE.ALL },
							{ title: t("pages.dashboard.games.partner_specific"), value: FILTER_GAME_SPECIFICATION_TYPE.PARTNER_SPECIFIC },
							{ title: t("pages.dashboard.games.generic"), value: FILTER_GAME_SPECIFICATION_TYPE.GENERIC }
						]
					}
				]
			: []),
		{
			name: "itemState",
			title: t("common.status"),
			values: [
				{ title: t("common.all"), value: FILTER_STATUS.ALL },
				{ title: t("common.active"), value: FILTER_STATUS.ACTIVE },
				{ title: t("common.inactive"), value: FILTER_STATUS.INACTIVE }
			]
		},
		{
			name: "testState",
			title: t("common.data_type"),
			values: [
				{ title: t("common.all"), value: FILTER_TEST_STATE.ALL },
				{ title: t("common.testing"), value: FILTER_TEST_STATE.TESTING },
				{ title: t("common.not_testing"), value: FILTER_TEST_STATE.NOTTESTING }
			]
		},
		{ name: "date", title: t("common.creation_date") },
		{ name: "lastUpdate", title: t("common.last_update_date") }
	];

	return (
		<FiltersWrapper loadFn={() => getGames(gameCategory)} setFiltersFn={(newFilter) => setGameFilters(newFilter, gameCategory)} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList} filtersName={filtersName}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.games.game_name_or_id")} name="nameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.games.game_name_or_id")}`} items={gameNames.map((g) => g.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.games.type")} name="gameType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{getGamesFromConfig(gameCategory).map((g) => (
									<Select.Option value={g.value} key={g.value}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					{gameCategory === GAME_CATEGORY.SCHEDULED && (
						<>
							<Col xs={24} sm={12} lg={6} xl={4}>
								<FormItem label={t("pages.dashboard.games.channel")} name="gameChannel">
									<Select suffixIcon={<i className="icon-down" />}>
										<Select.Option value={FILTER_GAME_CHANNEL_TYPE.ALL}>{t("common.all")}</Select.Option>
										<Select.Option value={FILTER_GAME_CHANNEL_TYPE.ONLINE}>{t("pages.dashboard.games.channels.online")}</Select.Option>
										<Select.Option value={FILTER_GAME_CHANNEL_TYPE.RETAIL}>{t("pages.dashboard.games.channels.retail")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
							<Col xs={24} sm={12} lg={6} xl={4}>
								<FormItem label={t("pages.dashboard.games.specification")} name="specificationType">
									<Select suffixIcon={<i className="icon-down" />}>
										<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.ALL}>{t("common.all")}</Select.Option>
										<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.PARTNER_SPECIFIC}>{t("pages.dashboard.games.partner_specific")}</Select.Option>
										<Select.Option value={FILTER_GAME_SPECIFICATION_TYPE.GENERIC}>{t("pages.dashboard.games.generic")}</Select.Option>
									</Select>
								</FormItem>
							</Col>
						</>
					)}

					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.status")} name="itemState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_STATUS.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_STATUS.ACTIVE}>{t("common.active")}</Select.Option>
								<Select.Option value={FILTER_STATUS.INACTIVE}>{t("common.inactive")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.data_type")} name="testState">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={FILTER_TEST_STATE.ALL}>{t("common.all")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.TESTING}>{t("common.testing")}</Select.Option>
								<Select.Option value={FILTER_TEST_STATE.NOTTESTING}>{t("common.not_testing")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.creation_date")} name="date">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day").endOf("day"))} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.last_update_date")} name="lastUpdate">
							<DatePicker.RangePicker placeholder={[t("common.from"), t("common.to")]} format={DATE_FORMAT} disabledDate={(d) => !d || d.isAfter(moment().endOf("day").endOf("day"))} />
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Redux action to set games filters */
	setGameFilters: PropTypes.func,
	/** Redux state property, games filters */
	filters: PropTypes.object,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getGames: (gameCategory) => {
		dispatch(getGames(false, false, gameCategory));
	},
	setGameFilters: (filters, gameCategory) => {
		dispatch(setGameFilters(filters, gameCategory));
	}
});

const mapStateToProps = (state, props) => {
	const { gameCategory } = props;
	return {
		filters: state.games.filters[gameCategory],
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
