export const PLAYER_SIZES = {
	XX_SMALL: "xxsmall",
	X_SMALL: "xsmall",
	SMALL: "small",
	MEDIUM: "medium",
	LARGE: "large"
}

export const HIDE_CONTROLS_CLASS = "vs-oven-player-hide-controls"

export const SOURCE_TYPES = {
	WEBRTC: "webrtc",         // Ultra-low latency streaming with WebRTC
	HLS: "hls",               // HTTP Live Streaming
	M3U8: "m3u8",             // Alternative for HLS; uses .m3u8 files as manifest
	DASH: "dash",             // MPEG-DASH streaming
	MPD: "mpd",               // Alternative for DASH; uses .mpd files as manifest
	MP4: "mp4",               // MP4 files (must be wrapped in HLS or DASH for OvenPlayer)
	RTMP: "rtmp"              // RTMP ingestion; requires conversion for playback
}