import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Tabs, Tooltip } from "antd";
import OtherConfigs from "./otherConfigs";
import RtpComponent from "./rtpComponent";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { POPUP_SIZE } from "constants/common.constants";
import { INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE, GAME_CATEGORY } from "constants/game.constants";

/** Apikey Edit game RTP | Label Popup Component */
const EditGameModal = ({ isModalVisible, setIsModalVisible, gameId, gameType, gameCategory }) => {
	const { t } = useTranslation();
	const hasViewApiKeyRtp = hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_RTP, action: PERMISSION_ACTION.VIEW });
	const hasViewApiKeyOtherConfigs = hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_OTHER_CONFIGS, action: PERMISSION_ACTION.VIEW });
	const [seletedTab, setSeletedTab] = useState(hasViewApiKeyRtp ? "1" : "2");

	const onClose = () => {
		setIsModalVisible({ id: null, type: null });
		setSeletedTab(hasViewApiKeyRtp ? "1" : "2");
	};

	return (
		<Modal
			open={isModalVisible}
			onCancel={() => setIsModalVisible({ id: null, type: null })}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			title={
				<Fragment>
					<span>{t("pages.dashboard.apikeys.edit_game")}</span>
					{Number(seletedTab) === 2 ? (
						<Tooltip
							title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.apikeys.game_add_info") }} />}
							getPopupContainer={() => document.body}
						>
							<i className="icon-info" />
						</Tooltip>
					) : null}
				</Fragment>
			}
			footer={null}
			centered
			destroyOnClose
			className="apikey-edit-game-modal"
		>
			<Tabs
				animated={false}
				activeKey={seletedTab}
				onChange={setSeletedTab}
				items={[
					hasViewApiKeyRtp
						? {
								key: "1",
								label: t(
									`pages.dashboard.apikeys.rtp.${gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value ? "factors_management" : "rtp_managment"}`
								),
								children: <RtpComponent gameCategory={gameCategory} onClose={onClose} gameId={gameId} gameType={gameType} />
							}
						: null,
					hasViewApiKeyOtherConfigs
						? {
								key: "2",
								label: t("pages.dashboard.apikeys.other_configs"),
								children: <OtherConfigs gameCategory={gameCategory} onClose={onClose} gameId={gameId} />
							}
						: null
				]}
			/>
		</Modal>
	);
};

EditGameModal.propTypes = {
	/** Game rtp | label data edit modal visibility state */
	isModalVisible: PropTypes.string,
	/** Set setCurrentRTPGameId and toggle popup state */
	setIsModalVisible: PropTypes.func,
	/** Api keys Current editing game id */
	gameId: PropTypes.string,
	/** Current game type */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

export default EditGameModal;
