import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";

import NanoVideoPlayer from "./nanoPlayer";
import WowzaVideoPlayer from "./wowzaPlayer";
import FlussonicVideoPlayer from "./flussonicPlayer";
import MP4VideoPlayer from "./mp4Player";
import OvenPlayerVideoPlayer from "./ovenPlayerVideoPlayer";

export const createPlayer = (type, streamConfiguration, options) => {
	return (
		type === GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS
		? new NanoVideoPlayer(streamConfiguration, options)
		: type === GAME_STREAM_CONFIGURATION_TYPE.WOWZA
			? new WowzaVideoPlayer(streamConfiguration, options)
			: type === GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC
				? new FlussonicVideoPlayer(streamConfiguration, options)
				: type === GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER
					? new OvenPlayerVideoPlayer(streamConfiguration, options)
					: new MP4VideoPlayer(streamConfiguration, options)
	)
};
