import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Input, Modal, Tooltip, Table } from "antd";
import { flagsToBinary, getGamesFromConfig } from "utils/common.js";
import { GAME_CATEGORY, GAME_LABEL_TYPE } from "constants/game.constants.js";
import { useEffect, useMemo, useState } from "react";
import { isFormChanged } from "utils/form.js";
import { SearchOutlined } from "@ant-design/icons";
import { addSSBTGames } from "store/actions/dashboard/ssbt/ssbt.action.js";

const GameAddComponent = ({ onSuccess, onClose }) => {
	const [selectedGameTypesAndIds, setSelectedGameTypesAndIds] = useState([]);
	const [formGames, setFormGames] = useState([]);
	const [search, setSearch] = useState("");
	const [isFormTouched, setIsFormTouched] = useState(false);

	const ssbtGames = useSelector((state) => state.ssbts.editingSSBT.games);
	const games = useSelector((state) => state.games.games);
	const isSaving = useSelector((state) => state.ssbts.isSaving);

	const dispatch = useDispatch();
	const routeParams = useParams();
	const { t } = useTranslation();

	const [form] = Form.useForm();
	const { getFieldValue, setFieldsValue } = form;

	const dataSource = useMemo(() => {
		return formGames.filter((game) => {
			return !search || game.name?.toLowerCase().includes(search.toLowerCase());
		});
	}, [formGames, search]);

	const columns = [
		{
			title: <span className="vs--pl-32">{t("pages.dashboard.games.game")}</span>,
			dataIndex: "column1",
			render: (_, record) => {
				return (
					<div className="vs--flex vs--justify-start vs--align-center">
						<Form.Item shouldUpdate noStyle>
							{() => {
								const fields = getAllValue();
								const checked = fields?.[record.id]?.checked ?? false;
								const isDisabled = selectedGameTypesAndIds.find((selectedGameTypeAndId) => {
									return record.id !== selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType;
								});

								return (
									<Checkbox
										disabled={isDisabled}
										checked={checked}
										onChange={(e) => {
											const newField = { ...fields };
											const newChecked = !checked;
											newField[record.id] = {
												...record,
												checked: newChecked,
												isNew: false,
												isTest: false
											};
											setFieldsValue(newField);
											if (newChecked) {
												setSelectedGameTypesAndIds((prev) => [
													...prev,
													{
														id: record.id,
														gameType: record.gameType
													}
												]);
											} else {
												setSelectedGameTypesAndIds((prev) =>
													prev.filter((selectedGameTypeAndId) => {
														return !(record.id === selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType);
													})
												);
											}
											handleFormChange();
										}}
									/>
								);
							}}
						</Form.Item>
						<span className="vs--ml-16">{record.name}</span>
					</div>
				);
			}
		},
		{
			title: `${t("pages.dashboard.ssbt.new")} ${t("pages.dashboard.games.game")}`,
			dataIndex: "column2",
			align: "center",
			render: (_, record) => {
				return (
					<Form.Item shouldUpdate noStyle>
						{() => {
							const game = getFieldValue([record.id]);
							const isGameChecked = game?.checked ?? false;
							const isChecked = game?.isNew ?? false;
							return (
								<Checkbox
									disabled={!isGameChecked}
									checked={isChecked}
									onChange={() => {
										const fields = getAllValue();
										const newField = { ...fields };

										newField[record.id] = { ...game, isNew: !isChecked };

										setFieldsValue(newField);
										handleFormChange();
									}}
								/>
							);
						}}
					</Form.Item>
				);
			}
		},
		{
			title: t("pages.dashboard.games.is_test_game"),
			dataIndex: "column3",
			align: "center",
			render: (_, record) => {
				return (
					<Form.Item shouldUpdate noStyle>
						{() => {
							const game = getFieldValue([record.id]);
							const isGameChecked = game?.checked ?? false;
							const isChecked = game?.isTest ?? false;
							return (
								<Checkbox
									disabled={!isGameChecked}
									checked={isChecked}
									onChange={() => {
										const fields = getAllValue();
										const newField = { ...fields };

										newField[record.id] = { ...game, isTest: !isChecked };

										setFieldsValue(newField);
										handleFormChange();
									}}
								/>
							);
						}}
					</Form.Item>
				);
			}
		}
	];

	const handleFormChange = () => {
		const formValues = getAllValue();
		const gameIds = Object.keys(getAllValue());
		const filtered = formGames.filter((formGame) => gameIds.includes(formGame.id));

		const generatedInitial = filtered.reduce((acc, game) => {
			acc[game.id] = game;
			return acc;
		}, {});

		setIsFormTouched(isFormChanged({ ...formValues }, generatedInitial));
	};

	const getAvailableTypes = () => {
		const possibleGames = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = ssbtGames.map((g) => g.type);

		possibleGames.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});

		return available;
	};

	const getAllValue = () => {
		return formGames.reduce((acc, game) => {
			const values = getFieldValue([game.id]);
			if (values) {
				acc[game.id] = values;
			}
			return acc;
		}, {});
	};

	const handleForm = () => {
		const fields = getAllValue();
		const games = Object.values(fields).reduce((acc, game) => {
			if (!game.checked) {
				return acc;
			}

			const gameLabelArr = [];

			if (game.isNew) {
				gameLabelArr.push(GAME_LABEL_TYPE.NEW);
			}

			if (game.isTest) {
				gameLabelArr.push(GAME_LABEL_TYPE.TEST);
			}

			const gameLabel = flagsToBinary(gameLabelArr);

			acc.push({
				gameId: game.id,
				gameLabel: gameLabel || null
			});

			return acc;
		}, []);

		dispatch(
			addSSBTGames(
				{
					id: routeParams.id,
					games
				},
				onSuccess
			)
		);
	};

	useEffect(() => {
		if (!games.length) {
			return;
		}

		const availableGames = getAvailableTypes();
		const initialGames = games
			.filter((game) => availableGames.includes(game.type))
			.map((game) => ({
				id: game.id,
				name: game.name,
				gameType: game.type,
				checked: false,
				isNew: false,
				isTest: false
			}));

		setFormGames(initialGames);
	}, [games]);

	return (
		<Modal
			open={true}
			title={
				<>
					<span>{t("pages.dashboard.ssbt.add_new_game")}</span>
					<Tooltip
						title={
							<div>
								<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.ssbt.game_add_info") }}></span>
							</div>
						}
						getPopupContainer={() => document.body}
					>
						<i className="icon-info" />
					</Tooltip>
				</>
			}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={800}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched }}
			centered
			className="ssbt-add-NewGame-modal"
		>
			<Form className="dashboard-form ssbt-edit-addNewGame" form={form} colon={false} layout="vertical" requiredMark={false} initialValues={{}}>
				<div className="vs--mb-8">
					<Input prefix={<SearchOutlined />} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>
				<div>
					<Table rowKey="id" columns={columns} dataSource={dataSource} pagination={false} />
				</div>
			</Form>
		</Modal>
	);
};

export default GameAddComponent;
