import { Checkbox, Form, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSSBTGameOtherConfigs, saveSSBTGameOtherConfigs } from "store/actions/dashboard/ssbt/ssbt.action.js";
import { useParams } from "react-router-dom";
import { hasPermission } from "utils/permissions.js";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import FormItem from "antd/es/form/FormItem/index.js";
import { useTranslation } from "react-i18next";
import FooterButtons from "components/dashboard/ssbt/edit/sections/games/editGameModal/footerButtons.jsx";
import { binaryToFlags, flagsToBinary } from "utils/common.js";
import { GAME_LABEL_TYPE_FLAGS } from "constants/game.constants.js";
import { isFormChanged } from "utils/form.js";

const formInitialValue = {
	gameLabel: []
};

const OtherConfigs = ({ gameId, onClose }) => {
	const isSaving = useSelector((state) => state.ssbts.isSaving);
	const gameLabel = useSelector((state) => state.ssbts.editingSSBT.otherConfigs.data.gameLabel);
	const isLoading = useSelector((state) => state.ssbts.editingSSBT.otherConfigs.isLoading);

	const [form] = Form.useForm();
	const [isFormTouched, setIsFormTouched] = useState(false);

	const dispatch = useDispatch();
	const routeParams = useParams();
	const { t } = useTranslation();

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_OTHER_CONFIGS, action: PERMISSION_ACTION.MODIFY });

	const saveButtonProps = {
		loading: isSaving,
		disabled: isDisabled || !isFormTouched,
		style: isDisabled ? { display: "none" } : {}
	};

	const onFinish = ({ gameLabel }) => {
		const submitData = { gameId, id: routeParams.id };

		const binary = flagsToBinary(gameLabel);

		if (binary) {
			submitData.gameLabel = binary;
		}

		dispatch(saveSSBTGameOtherConfigs(submitData, () => onClose(null)));
	};

	const onFieldsChange = (_, allFields) => {
		const changedData = allFields.reduce((data, changedField) => {
			const name = changedField?.name?.[0];
			const value = changedField?.value;

			data[name] = value.toString();

			return data;
		}, {});

		setIsFormTouched(
			isFormChanged(
				{
					gameLabel: Array.isArray(gameLabel) ? gameLabel.toString() : gameLabel
				},
				changedData
			)
		);
	};

	useEffect(() => {
		if (gameLabel) {
			form.setFieldsValue({
				gameLabel: binaryToFlags(GAME_LABEL_TYPE_FLAGS, gameLabel)
			});
		}

		return () => {
			form.setFieldsValue(formInitialValue);
		};
	}, [gameLabel, form]);

	useEffect(() => {
		dispatch(getSSBTGameOtherConfigs(routeParams.id, gameId));
	}, [gameId, routeParams.id]);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Form
				form={form}
				onFinish={onFinish}
				onFieldsChange={onFieldsChange}
				className="dashboard-form"
				colon={false}
				requiredMark={false}
				layout="vertical"
				initialValues={formInitialValue}
			>
				<FormItem name="gameLabel">
					<Checkbox.Group>
						<Checkbox value={1} style={{ lineHeight: "32px" }} disabled={isDisabled}>
							{t("pages.dashboard.ssbt.new_game")}
						</Checkbox>
						<Checkbox value={2} style={{ lineHeight: "32px", marginLeft: "11px" }} disabled={isDisabled}>
							{t("pages.dashboard.ssbt.is_test")}
						</Checkbox>
					</Checkbox.Group>
				</FormItem>
			</Form>
			<FooterButtons onClose={onClose} onSave={form.submit} saveButtonProps={saveButtonProps} />
		</Space>
	);
};

export default OtherConfigs;
