import { useEffect, useState, Fragment } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, Select, Input, Spin, Tooltip } from "antd";
const { Item: FormItem } = Form;

import BannerUpload from "components/dashboard/common/bannerUpload";

import { saveGameGeneralInfo, deleteLiveMonitorLogo, setLiveMonitorLogoId, resetGameGeneralInfo } from "store/actions/dashboard/virtuals/games/general.action";
import { setGlobalPartnerId } from "store/actions/dashboard/partners/partner.action";

import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { isSeasonGame, isLeagueGame, isCupGame, getGamesFromConfig } from "utils/common";

import {
	SCHEDULED_GAME_TYPE,
	GAME_CYCLE_MINUTES,
	ACTIVE_GAMES_COUNT_POSSIBLE_VALUES,
	LEAGUE_ACTIVE_GAMES_COUNT_POSSIBLE_VALUES,
	BETS_CLOSE_IN_POSSIBLE_VALUES,
	GAME_CATEGORY,
	GAME_CHANNEL_TITLE
} from "constants/game.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import ApiUrls from "constants/api.constants";

import gameGeneralInfoType from "types/game/generalInfo.type";
import { FILE_SIZES_MEASUREMENT } from "constants/common.constants";

/** Game Edit Page General Info Tab Component */
const GeneralInfoComponent = ({ saveGameGeneralInfo, isSaving, isLoading, generalInfo, onTabChange, setGlobalPartnerId, deleteLiveMonitorLogo, setLiveMonitorLogoId, resetGameGeneralInfo, gameCategory }) => {
	const routeParams = useParams();
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue, getFieldValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [closeBetSecondsDisabled, setCloseBetSecondsDisabled] = useState(false);

	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.MODIFY });

	/** Reset game general info component unmounth */
	useEffect(() => {
		return () => {
			resetGameGeneralInfo();
		};
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({
			...(gameCategory === GAME_CATEGORY.SCHEDULED
				? {
						cycleMinutes: generalInfo.cycleMinutes,
						closeBetSeconds: generalInfo.closeBetSeconds,
						activeGameCount: generalInfo.activeGameCount
					}
				: null),
			sceneCount: generalInfo.sceneCount,
			name: generalInfo.name,
			type: generalInfo.type,
			gameChannel: generalInfo.gameChannel,
		});

		if (generalInfo.partnerId) {
			setGlobalPartnerId(generalInfo.partnerId);
		}

		if (gameCategory === GAME_CATEGORY.SCHEDULED && getFieldValue("cycleMinutes") === GAME_CYCLE_MINUTES.CYCLE1MIN) {
			!isDisabled && setCloseBetSecondsDisabled(true);
		}
	}, [generalInfo, isDisabled]);

	/** Fires when form submitted
	 * @function
	 * @memberOf GeneralInfoComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((data) => {
				saveGameGeneralInfo(
					{
						...data,
						id: routeParams.id,
						...(gameCategory === GAME_CATEGORY.SCHEDULED
							? {
									activeGameCount: data.activeGameCount
								}
							: null)
					},
					gameCategory
				);
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				validateTrigger="onSubmit"
				initialValues={{
					name: generalInfo.name,
					sceneCount: generalInfo.sceneCount,
					type: generalInfo.type,
					...(gameCategory === GAME_CATEGORY.SCHEDULED
						? {
								cycleMinutes: generalInfo.cycleMinutes,
								closeBetSeconds: generalInfo.closeBetSeconds,
								activeGameCount: generalInfo.activeGameCount
							}
						: null)
				}}
				onValuesChange={(changed, formValues) => {
					setIsFormTouched(isFormChanged({ ...formValues }, { ...generalInfo }));
					if (gameCategory === GAME_CATEGORY.SCHEDULED) {
						if (getFieldValue("cycleMinutes") === GAME_CYCLE_MINUTES.CYCLE1MIN) {
							setFieldsValue({ closeBetSeconds: BETS_CLOSE_IN_POSSIBLE_VALUES[0] });
							setCloseBetSecondsDisabled(true);
						} else {
							setCloseBetSecondsDisabled(false);
						}
					}
				}}
			>
				<div className="dashboard-section-content">
					<div>
						<Row gutter={[40, 0]}>
							<Col xs={24} sm={24} xl={12}>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1>{t("pages.dashboard.games.main_configuration")}</h1>
									</Col>
									<Col xs={24} sm={12}>
										<FormItem label={t("pages.dashboard.games.game_type")} name="type">
											<Select disabled={true} suffixIcon={<i className="icon-down" />}>
												{getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((g) => (
													<Select.Option value={g.value} key={g.value}>
														{t(`common.${g.type}`)}
													</Select.Option>
												))}
											</Select>
										</FormItem>
									</Col>
									{gameCategory === GAME_CATEGORY.SCHEDULED && (
										<>
											<Col xs={24} sm={12}>
												<FormItem label={t("pages.dashboard.games.channel")} name="gameChannel">
													<Select disabled suffixIcon={<i className="icon-down" />} value={generalInfo.gameChannel}>
														<Select.Option value={generalInfo.gameChannel}>
															{t(`pages.dashboard.games.channels.${GAME_CHANNEL_TITLE[generalInfo.gameChannel]}`)}
														</Select.Option>
													</Select>
												</FormItem>
											</Col>
											<Col xs={24} sm={12}>
												<FormItem label={t("pages.dashboard.games.specify_partner")}>
													<Input value={generalInfo.partnerName ? generalInfo.partnerName + (generalInfo.isTesting ? " (Test)" : "") : t("common.none")} disabled={true} />
												</FormItem>
											</Col>
										</>
									)}

									<Col xs={24} sm={12}>
										<FormItem label={`${t("pages.dashboard.games.name")} *`} name="name" rules={[{ required: true, whitespace: true, message: t("validation.field_required") }]}>
											<Input maxLength={50} placeholder={`${t("common.enter")} ${t("pages.dashboard.games.name")}`} disabled={isDisabled} />
										</FormItem>
									</Col>
								</Row>
								<Row gutter={[16, 0]}>
									<Col span={24}>
										<h1 className="dashboard-section-name">{t("pages.dashboard.games.game_configuration")}</h1>
									</Col>
									{(generalInfo.type === SCHEDULED_GAME_TYPE.FOOTBALL_SINGLE_MATCH.value || isSeasonGame(generalInfo.type)) && (
										<Col xs={24} sm={12}>
											<FormItem label={t("pages.dashboard.games.scene_count")} name="sceneCount">
												<Input disabled={true} />
											</FormItem>
										</Col>
									)}
									{gameCategory === GAME_CATEGORY.SCHEDULED ? (
										<Fragment>
											<Col xs={24} sm={12}>
												<FormItem label={isLeagueGame(generalInfo.type) ? t("pages.dashboard.games.week_cycle") : isCupGame(generalInfo.type) ? t("pages.dashboard.games.round_cycle") : t("pages.dashboard.games.draw_cycle")} name="cycleMinutes">
													<Select disabled={isDisabled} suffixIcon={<i className="icon-down" />}>
														{generalInfo.type !== SCHEDULED_GAME_TYPE.PENALTY_SHOOTOUT.value ? <Select.Option value={GAME_CYCLE_MINUTES.CYCLE2MIN}>{`2 ${t("common.minutes")}`}</Select.Option> : null}
														<Select.Option value={GAME_CYCLE_MINUTES.CYCLE3MIN}>{`3 ${t("common.minutes")}`}</Select.Option>
														<Select.Option value={GAME_CYCLE_MINUTES.CYCLE4MIN}>{`4 ${t("common.minutes")}`}</Select.Option>
														<Select.Option value={GAME_CYCLE_MINUTES.CYCLE5MIN}>{`5 ${t("common.minutes")}`}</Select.Option>
														<Select.Option value={GAME_CYCLE_MINUTES.CYCLE6MIN}>{`6 ${t("common.minutes")}`}</Select.Option>
													</Select>
												</FormItem>
											</Col>
											<Col xs={24} sm={12}>
												<FormItem
													label={isLeagueGame(generalInfo.type) ? `${t("pages.dashboard.games.active_week_count")} *` : isCupGame(generalInfo.type) ? `${t("pages.dashboard.games.active_round_count")} *` : `${t("pages.dashboard.games.active_game_count")} *`}
													name="activeGameCount"
													rules={[{ required: true, message: t("validation.field_required") }]}
												>
													<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled || isCupGame(generalInfo.type)}>
														{(isLeagueGame(generalInfo.type) ? LEAGUE_ACTIVE_GAMES_COUNT_POSSIBLE_VALUES : ACTIVE_GAMES_COUNT_POSSIBLE_VALUES).map((v) => (
															<Select.Option key={v} value={v}>
																{v}
															</Select.Option>
														))}
													</Select>
												</FormItem>
											</Col>
											<Col xs={24} sm={12}>
												<FormItem label={`${t("pages.dashboard.games.bets_close_before_start")} *`} name="closeBetSeconds" rules={[{ required: true, message: t("validation.field_required") }]}>
													<Select suffixIcon={<i className="icon-down" />} disabled={isDisabled || closeBetSecondsDisabled}>
														{BETS_CLOSE_IN_POSSIBLE_VALUES.map((v) => (
															<Select.Option key={v} value={v}>
																{v + " " + t("common.seconds")}
															</Select.Option>
														))}
													</Select>
												</FormItem>
											</Col>
										</Fragment>
									) : null}
									<Col xs={24} sm={24} md={16} xl={12}>
										{generalInfo.liveMonitorLogoId !== undefined && !isLoading && (
											<FormItem
												label={
													<Fragment>
														<h1 className="dashboard-section-name" style={{ marginBottom: "8px" }}>
															{t("pages.dashboard.games.live_monitor_logo")}
															<Tooltip title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.games.live_monitor_logo_tooltip") }}></span>}>
																<i className="icon-info" style={{ fontSize: "24px" }} />
															</Tooltip>
														</h1>
													</Fragment>
												}
											>
												<BannerUpload
													uploadUrl={`${import.meta.env.SYSTEM_API_URL}${ApiUrls.SCHEDULED_GAME_UPLOAD_LIVE_MONITOR_LOGO}`}
													defaultFile={
														generalInfo.liveMonitorLogoId
															? {
																	url: `${import.meta.env.SYSTEM_CDN_URL}/${generalInfo.partnerId ? generalInfo.partnerId : "system"}/games/${generalInfo.id}/livemonitorlogo_${generalInfo.liveMonitorLogoId}_small.png`,
																	status: "done",
																	percent: 100
																}
															: null
													}
													remove={
														!isDisabled
															? {
																	handler: () => deleteLiveMonitorLogo(routeParams.id),
																	message: t("pages.dashboard.games.remove_logo_confirmation_message")
																}
															: null
													}
													data={{ gameId: routeParams.id }}
													fileBuilder={(value) => {
														const endpoint = generalInfo.partnerId ? generalInfo.partnerId : "system";
														return {
															url: `${import.meta.env.SYSTEM_CDN_URL}/${endpoint}/games/${generalInfo.id}/livemonitorlogo_${value}_small.png`
														};
													}}
													extensions={["image/png"]}
													size={FILE_SIZES_MEASUREMENT.MB}
													disabled={isDisabled}
													disablePreview={true}
													onSuccess={(logoId) => {
														setLiveMonitorLogoId(logoId);
													}}
												/>
											</FormItem>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					{!isDisabled ? (
						<FormItem className="button-container">
							<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
								<span>{t("common.save")}</span>
							</Button>
						</FormItem>
					) : null}
				</div>
			</Form>
		</Spin>
	);
};

/** GeneralInfoComponent propTypes
 * PropTypes
 */
GeneralInfoComponent.propTypes = {
	/** Redux action to save game General info */
	saveGameGeneralInfo: PropTypes.func,
	/** Redux state property, is true when general info is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when general info is loading */
	isLoading: PropTypes.bool,
	/** Redux state, represents the general info of current editing game  */
	generalInfo: gameGeneralInfoType,
	/** Redux action to set global partner id */
	setGlobalPartnerId: PropTypes.func,
	/** Redux action to delete live monitor logo */
	deleteLiveMonitorLogo: PropTypes.func,
	/** Redux action to set live monitor logo id */
	setLiveMonitorLogoId: PropTypes.func,
	/** Redux action to reset game general info */
	resetGameGeneralInfo: PropTypes.func,
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	saveGameGeneralInfo: (data, gameCategory) => {
		dispatch(saveGameGeneralInfo(data, gameCategory));
	},

	setGlobalPartnerId: (partnerId) => {
		dispatch(setGlobalPartnerId(partnerId));
	},

	deleteLiveMonitorLogo: (id) => {
		dispatch(deleteLiveMonitorLogo(id));
	},

	setLiveMonitorLogoId: (id) => {
		dispatch(setLiveMonitorLogoId(id));
	},

	resetGameGeneralInfo: () => {
		dispatch(resetGameGeneralInfo());
	}
});

const mapStateToProps = (state) => {
	return {
		generalInfo: state.games.editingGame.generalInfo,
		isSaving: state.games.isSaving,
		isLoading: state.games.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);
