import { useState, useEffect, Fragment, useMemo } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { Form, Col, Row, Modal, Select, Checkbox, Tooltip, Table, Input } from "antd";
const { Item: FormItem } = Form;
import { SearchOutlined } from "@ant-design/icons";

import { addBoxGames } from "store/actions/dashboard/retail/boxes/games.action";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action";

import { getGamesFromConfig } from "utils/common";
import { isFormChanged } from "utils/form";

import gameType from "types/game/game.type";

import { POPUP_SIZE } from "constants/common.constants";
import { GAME_CATEGORY, GAME_CHANNEL } from "constants/game.constants";
import boxGameType from "types/box/boxGame.type";

/** Box Game Adding Popup Component */
const GameAddComponent = ({ isSaving, addBoxGames, getGames, onClose, games, boxGames }) => {
	const routeParams = useParams();
	const { t } = useTranslation();

	const [formInstance] = Form.useForm();
	const { getFieldValue, getFieldsValue, setFieldValue, setFieldsValue } = formInstance;


	const getAvailableTypes = () => {
		const possible = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = boxGames.map((g) => g.type);
		possible.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});
		return available;
	};

	const [selectedGameTypesAndIds, setSelectedGameTypesAndIds] = useState([]);
	const [formGames, setFormGames] = useState([]);
	const [search, setSearch] = useState("");
	const [isFormTouched, setIsFormTouched] = useState(false);

	/** Get all games */
	useEffect(() => {
		getGames(GAME_CATEGORY.SCHEDULED);
	}, []);

	/** Fires when form submitted
	 * @function
	 * @memberOf GameAddComponent
	 */
	const handleForm = () => {
		const fields = getAllValue();
		const games = Object.values(fields).reduce((acc, game) => {
			if (!game.checked) {
				return acc;
			}

			acc.push({
				gameId: game.id
			})

			return acc
		}, [])
		addBoxGames({
			id: routeParams.id,
			games
		})
	};

	useEffect(() => {
		if (!games.length) {
			return
		}

		const available = getAvailableTypes();
		const initial = games
			.filter(game => available.includes(game.type))
			.map((game) => ({
				id: game.id,
				name: game.name,
				gameType: game.type,
				checked: false
			}))

		setFormGames(initial)

	}, [games, setFormGames])

	const getAllValue = () => {
		return formGames.reduce((acc, game) => {
			const values = getFieldValue([game.id]);
			if (values) {
				acc[game.id] = values
			}
			return acc
		}, {})
	}

	const handleFormChange = () => {
		const formValues = getAllValue()
		const gameIds = Object.keys(getAllValue())
		const filtered = formGames.filter(formGame => gameIds.includes(formGame.id))
		const generatedInitial = filtered.reduce((acc, game) => {
			acc[game.id] = game;
			return acc
		}, {})
		setIsFormTouched(isFormChanged({ ...formValues }, generatedInitial))
	};

	const dataSource = useMemo(() => {
		return formGames.filter((game) => {
			return !search || game.name?.toLowerCase().includes(search.toLowerCase())
		});
	}, [formGames, search])

	const columns = [
		{
			title: <span className="vs--pl-32">{t("pages.dashboard.games.game")}</span>,
			dataIndex: 'column1',
			render: (_, record, index) => {
				return (
					<div className="vs--flex vs--justify-start vs--align-center">
						<Form.Item shouldUpdate noStyle>
							{
								() => {
									const fields = getAllValue()
									const checked = fields?.[record.id]?.checked ?? false;
									const isDisabled = selectedGameTypesAndIds.find(selectedGameTypeAndId => {
										return record.id !== selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType
									})
									return (
										<Checkbox
											disabled={isDisabled}
											checked={checked}
											onChange={(e) => {
												const newField = { ...fields }
												const newChecked = !checked;
												newField[record.id] = { ...record, checked: newChecked, isNew: false, isTest: false };
												setFieldsValue(newField);
												if (newChecked) {
													setSelectedGameTypesAndIds(prev => ([...prev, { id: record.id, gameType: record.gameType }]))
												} else {
													setSelectedGameTypesAndIds(prev => prev.filter(selectedGameTypeAndId => {
														return !(record.id === selectedGameTypeAndId.id && record.gameType === selectedGameTypeAndId.gameType)
													}))
												}
												handleFormChange()
											}}
										/>
									)
								}
							}
						</Form.Item>
						<span className="vs--ml-16">
							{record.name}
						</span>
					</div>
				)
			}
		}
	];

	return (
		<Modal
			open={true}
			title={
				<Fragment>
					<span>{t("pages.dashboard.apikeys.add_new_game")}</span>
				</Fragment>
			}
			cancelText={t("common.cancel")}
			okText={t("common.add")}
			onOk={handleForm}
			onCancel={onClose}
			width={POPUP_SIZE.MIDDLE}
			maskClosable={false}
			closable={false}
			okButtonProps={{ loading: isSaving, disabled: !isFormTouched }}
			centered
			className="apikey-add-NewGame-modal"
		>
			<Form
				className="dashboard-form apikey-edit-addNewGame"
				form={formInstance}
				colon={false}
				layout="vertical"
				requiredMark={false}
				initialValues={{}}
			>
				<div className="vs--mb-8">
					<Input prefix={<SearchOutlined />} placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
				</div>
				<div>
					<Table
						rowKey="id"
						columns={columns}
						dataSource={dataSource}
						pagination={false}
					/>
				</div>
			</Form>
		</Modal>
	);
};

/** GameAddComponent propTypes
 * PropTypes
 */
GameAddComponent.propTypes = {
	/** Redux state property, is true when adding game for api key */
	isSaving: PropTypes.bool,
	/** Redux action to add game to api key*/
	addBoxGames: PropTypes.func,
	/** Redux action to get games */
	getGames: PropTypes.func,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Redux state property, represents the array of games  */
	games: PropTypes.arrayOf(gameType),
	/** Redux state property, represents the array of games of api key */
	boxGames: PropTypes.arrayOf(boxGameType),
};

const mapDispatchToProps = (dispatch) => ({
	addBoxGames: (gamesData) => {
		dispatch(addBoxGames(gamesData));
	},
	getGames: (gameCategory) => {
		dispatch(getGames(false, true, gameCategory, GAME_CHANNEL.RETAIL));
	}
});

const mapStateToProps = (state, props) => {
	return {
		isSaving: state.boxes.isSaving,
		boxGames: state.boxes.editingBox.games.games,
		games: state.games.games
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameAddComponent);
