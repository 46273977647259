import {
	SET_SSBTS_ACTION_FINISH,
	SET_SSBTS_ACTION_BEFORE,
	SET_SSBTS_ACTION,
	SET_SSBTS_SORTING_ACTION,
	SET_SSBTS_FILTERS_ACTION,
	SET_EDITING_SSBT_GAMES_ACTION,
	SET_SSBT_UPDATE_GAME,
	SET_SSBT_SAVE_ACTION_BEFORE,
	SET_SSBT_SAVE_ACTION_FINISH,
	SET_SSBT_DELETE_GAME,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION_BEFORE,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION_FINISH,
	SET_SSBT_GAME_OTHER_CONFIGS_ACTION
} from "store/actionTypes.js";
import axios from "axios";
import ApiUrls from "constants/api.constants.js";
import Methods from "constants/methods.constants.js";
import { GAME_CATEGORY } from "constants/game.constants.js";
import { RESPONSE_STATE } from "constants/response.constants.js";
import { message } from "antd";

export const setSSBTsActionBefore = () => ({
	type: SET_SSBTS_ACTION_BEFORE
});

export const setSSBTsActionFinish = () => ({
	type: SET_SSBTS_ACTION_FINISH
});

export const setSSBTsAction = (payload) => ({
	type: SET_SSBTS_ACTION,
	payload
});

export const setSSBTsSortingAction = (sorting) => ({
	type: SET_SSBTS_SORTING_ACTION,
	payload: sorting
});

export const setSSBTsFiltersAction = (filters) => ({
	type: SET_SSBTS_FILTERS_ACTION,
	payload: filters
});

export const setEditingSSBTGamesAction = (games) => ({
	type: SET_EDITING_SSBT_GAMES_ACTION,
	payload: games
});

export const setSSBTUpdateGamesAction = (game) => ({
	type: SET_SSBT_UPDATE_GAME,
	payload: game
});

export const setSSBTSaveActionBefore = () => ({
	type: SET_SSBT_SAVE_ACTION_BEFORE
});

export const setSSBTSaveActionFinished = () => ({
	type: SET_SSBT_SAVE_ACTION_FINISH
});

export const setSSBTDeleteGame = (gameId) => ({
	type: SET_SSBT_DELETE_GAME,
	payload: gameId
});

export const getSSBTs = (fromFirstPage, loadAll) => {
	return (dispatch, getState) => {
		dispatch(setSSBTsActionBefore());
		let params = null;
		if (loadAll) {
			params = { page: 1, limit: 500 };
		} else {
			params = {
				...getState().ssbts.sorting,
				...getState().ssbts.filters,
				page: fromFirstPage ? 1 : getState().ssbts.sorting.page
			};
		}

		params.gameCategory = GAME_CATEGORY.SCHEDULED;

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SSBTS}`,
			method: Methods.GET,
			params: params
		})
			.then(({ data: { value: data } }) => {
				dispatch(setSSBTsAction(data));
				if (fromFirstPage)
					dispatch(
						setSSBTsSortingAction({
							...getState().ssbts.sorting,
							page: 1
						})
					);
				dispatch(setSSBTsActionFinish());
			})
			.catch(() => setSSBTsActionFinish());
	};
};

export const setSSBTGamesActionBefore = () => ({
	type: SET_SSBTS_ACTION_BEFORE
});

export const setSSBTGamesActionFinish = () => ({
	type: SET_SSBTS_ACTION_FINISH
});

export const getEditingSSBTGames = (id) => {
	return (dispatch) => {
		dispatch(setSSBTGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SSBT_SCHEDULED_GAMES}`,
			method: Methods.GET,
			params: { id }
		})
			.then(({ data: { value: data } }) => {
				dispatch(setEditingSSBTGamesAction(data));
				dispatch(setSSBTGamesActionFinish());
			})
			.catch(() => dispatch(setSSBTGamesActionFinish()));
	};
};

export const saveSSBTGames = (id, games) => {
	return (dispatch) => {
		dispatch(setSSBTGamesActionBefore());
		dispatch(setEditingSSBTGamesAction(games));

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SSBT_SCHEDULES_GAMES}`,
			method: Methods.POST,
			data: { id, gameIds: games.map((g) => g.id) }
		})
			.then(({ data: { value: games } }) => {
				dispatch(setEditingSSBTGamesAction(games));
				dispatch(setSSBTGamesActionFinish());
			})
			.catch(() => dispatch(setSSBTGamesActionFinish()));
	};
};

export const updateSSBTGame = (gameData) => {
	return (dispatch) => {
		dispatch(setSSBTGamesActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SSBT_SCHEDULES_GAME}`,
			method: Methods.POST,
			data: gameData
		})
			.then(({ data: { value: game } }) => {
				dispatch(setSSBTUpdateGamesAction(game));
				dispatch(setSSBTGamesActionFinish());
			})
			.catch(() => dispatch(setSSBTGamesActionFinish()));
	};
};

export const addSSBTGames = (gamesData, onSuccess) => {
	return (dispatch) => {
		dispatch(setSSBTSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.ADD_SSBT_SCHEDULED_GAMES}`,
			method: Methods.POST,
			data: gamesData
		})
			.then(({ data: { status, message: msg } }) => {
				if (status === RESPONSE_STATE.SUCCESS) {
					message.success(msg);
					onSuccess && onSuccess();
				}
				dispatch(setSSBTSaveActionFinished());
			})
			.catch(() => dispatch(setSSBTSaveActionFinished()));
	};
};

export const deleteSSBTGame = (id, gameId) => {
	return (dispatch) => {
		dispatch(setSSBTSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.DELETE_SSBT_SCHEDULED_GAME}`,
			method: Methods.DELETE,
			data: { id, gameId }
		})
			.then(() => {
				dispatch(setSSBTDeleteGame(gameId));
				dispatch(setSSBTSaveActionFinished());
			})
			.catch(() => dispatch(setSSBTSaveActionFinished()));
	};
};

const setSSBTGameOtherConfigsActionBefore = () => ({
	type: SET_SSBT_GAME_OTHER_CONFIGS_ACTION_BEFORE
});

const setSSBTGameOtherConfigsActionFinish = () => ({
	type: SET_SSBT_GAME_OTHER_CONFIGS_ACTION_FINISH
});

const setSSBTGameOtherConfigs = (data) => ({
	type: SET_SSBT_GAME_OTHER_CONFIGS_ACTION,
	payload: data
});

export const getSSBTGameOtherConfigs = (id, gameId) => {
	return (dispatch) => {
		dispatch(setSSBTGameOtherConfigsActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SSBT_GAME_OTHER_CONFIGS}`,
			method: Methods.GET,
			params: { id, gameId }
		})
			.then(({ data: { value } }) => {
				dispatch(setSSBTGameOtherConfigs(value));
				dispatch(setSSBTGameOtherConfigsActionFinish());
			})
			.catch(() => dispatch(setSSBTGameOtherConfigsActionFinish()));
	};
};

export const saveSSBTGameOtherConfigs = (data, callback) => {
	return (dispatch) => {
		dispatch(setSSBTSaveActionBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.SAVE_SSBT_GAME_OTHER_CONFIGS}`,
			method: Methods.POST,
			data
		})
			.then(({ data: { value } }) => {
				dispatch(setSSBTGameOtherConfigs(value));
				dispatch(getEditingSSBTGames(data.id));
			})
			.finally(() => {
				dispatch(setSSBTSaveActionFinished());
				if (typeof callback === "function") {
					callback();
				}
			});
	};
};
