import { Modal, Tabs, Tooltip } from "antd";
import { hasPermission } from "utils/permissions.js";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import OtherConfigs from "components/dashboard/ssbt/edit/sections/games/editGameModal/otherConfigs.jsx";
import { useTranslation } from "react-i18next";
import { POPUP_SIZE } from "constants/common.constants.js";

const EditGameModal = ({ isModalVisible, gameId, setIsModalVisible }) => {
	const { t } = useTranslation();
	const hasViewApiKeyOtherConfigs = hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_OTHER_CONFIGS, action: PERMISSION_ACTION.VIEW });

	return (
		<Modal
			open={isModalVisible}
			onCancel={() => setIsModalVisible({ id: null })}
			width={POPUP_SIZE.BIG}
			maskClosable={false}
			closable={false}
			footer={null}
			centered
			destroyOnClose
			className="ssbt-edit-game-modal"
			title={
				<>
					<span>{t("pages.dashboard.ssbt.edit_game")}</span>
					<Tooltip
						title={<span dangerouslySetInnerHTML={{ __html: t("pages.dashboard.ssbt.game_add_info") }} />}
						getPopupContainer={() => document.body}
					>
						<i className="icon-info" />
					</Tooltip>
				</>
			}
		>
			<Tabs
				animated={false}
				items={[
					hasViewApiKeyOtherConfigs && {
						key: "1",
						label: t("pages.dashboard.ssbt.other_configs"),
						children: <OtherConfigs gameId={gameId} onClose={() => setIsModalVisible({ id: null })} />
					}
				]}
			/>
		</Modal>
	);
};

export default EditGameModal;
