import { useMemo, useState, useEffect } from "react";

import { PERIOD } from "constants/market.constants";

import { rtpPeriodChecking } from "utils/markets";
import { SCHEDULED_GAME_TYPE } from "constants/game.constants";

const mutateRtpObject = (rtpObj, index) => ({ ...rtpObj, index, key: rtpObj.name + rtpObj.period });

const useRTPs = (rtp, orders, columns, gameType) => {
	const [data, setData] = useState([]);

	/* Instruction to filter / sort / mutate rtps data */
	const mutatedData = useMemo(() => {
		if (!data.length) {
			return data;
		}

		const sortedConfigLength = Object.keys(orders).length;
		if (!sortedConfigLength) {
			return data;
		}

		const mutated = {};
		let element = null;
		let sortedIndex = -1;

		/* There is a problem with performance, that is why need to use for loop. */
		for (let i = 0; i < rtp.rtPs.length; i++) {
			element = rtp.rtPs[i];
			if(gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value && !element.name?.startsWith("6")) {
				continue;
			}
			if (!rtpPeriodChecking(element, PERIOD.MATCH, PERIOD.NONE, PERIOD.PENALTY_SHOOTOUT)) {
				continue;
			}
			sortedIndex = orders[element.name] - 1;
			mutated[sortedIndex] = mutateRtpObject(element, sortedIndex);
		}

		return Object.values(mutated);
	}, [data, orders]);

	/** Function to get table details data
	 * @function
	 * @param {object} record - row item
	 * @returns {object} - table details data
	 * @memberOf CommonRTPComponent
	 */
	const getDetailsData = (record) => ({
		columns: columns,
		data: data.reduce((acc, elem, index) => {
			if (elem.name === record.name && rtpPeriodChecking(elem, PERIOD.FIRSTHALF, PERIOD.SECONDHALF)) {
				acc.push(mutateRtpObject(elem, index));
			}
			return acc;
		}, []),
		uniqueKey: "index"
	});

	useEffect(() => {
		setData(rtp.rtPs);
	}, [rtp.rtPs]);

	return {
		mutatedData,
		getDetailsData,
		columns
	};
};

export default useRTPs;
