import { useMemo, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Form, Row, Col, DatePicker, Radio, Tooltip, Select } from "antd";
const { Item: FormItem } = Form;

import locale from "antd/es/date-picker/locale/en_US";

import { getBetshopPerformanceReport, setBetshopPerformanceReportFilters } from "store/actions/dashboard/reports/betshopPerformance/betshopPerformance.action";

import FiltersWrapper from "components/common/filters";
import SearchableSelect from "components/common/searchableSelect";

import useAutosuggestion from "hooks/useAutosuggestion";

import { monthAgo } from "utils/dateTime";
import { getGamesFromConfig } from "utils/common";

import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
import { GAME_CATEGORY, SCHEDULED_GAME_TYPE } from "constants/game.constants";
import { DATE_FORMAT } from "constants/date.constants";

/** Betshop Performance Report Page Filters Component */
const Filters = ({ setBetshopPerformanceReportFilters, getBetshopPerformanceReport, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const betshopNames = useAutosuggestion(AUTOSUGGESTION_TYPE.BETSHOP_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });

	const formFieldsConfigs = useMemo(() => {
		return {
			datePicker: [{ name: "date", keepTime: true, roundTime: true }]
		};
	}, []);

	/** Function to get filters list
	 * @function
	 * @returns {array} - filters list
	 * @memberOf Filters
	 */
	const filtersList = () => [
		{ name: "id", title: t("pages.dashboard.reports.betshop"), values: [{ key: "", value: t("common.all") }, ...betshopNames].map((b) => ({ title: b.value, value: b.key })) },
		{
			name: "gameType",
			title: t("pages.dashboard.reports.game_type"),
			values: [{ title: t("common.all"), value: "" }].concat(
				getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((g) => ({
					title: t(`common.${g.type}`),
					value: g.value
				}))
			)
		}
	];

	return (
		<FiltersWrapper filtersName="betshopPerformance" loadFn={() => getBetshopPerformanceReport()} setFiltersFn={(filters) => setBetshopPerformanceReportFilters(filters)} filters={filters} formFieldsConfigs={formFieldsConfigs} filtersList={filtersList()} defaultOpened={true}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.reports.betshop")} name="id">
							<SearchableSelect items={[{ key: "", value: t("common.all") }, ...betshopNames]} valueProp={(item) => item.key} textProp={(item) => item.value} renderText={(item) => item.value} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.reports.game_type")} name="gameType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value={""}>
									<span title={t("common.all")}>{t("common.all")}</span>
								</Select.Option>
								{getGamesFromConfig(GAME_CATEGORY.SCHEDULED).filter(g => g.value !== SCHEDULED_GAME_TYPE.LUCKY_SIX.value).map((g) => (
									<Select.Option value={g.value} key={g.value} title={t(`common.${g.type}`)}>
										{t(`common.${g.type}`)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={24} lg={12} xl={8}>
						<FormItem
							label={
								<Fragment>
									<span>{t("pages.dashboard.events.date_and_time")}</span>
									<Tooltip title={t("common.for_more_data")} getPopupContainer={() => document.body}>
										<i className="icon-info table-filters-info" />
									</Tooltip>
								</Fragment>
							}
							name="date"
						>
							<DatePicker.RangePicker
								placeholder={[t("common.from"), t("common.to")]}
								format={`${DATE_FORMAT} HH:00`}
								showTime={{ format: "HH" }}
								disabledDate={(d) => !d || d.isBefore(monthAgo(3)) || d.isAfter(moment().endOf("day"))}
								allowClear={false}
								locale={{
									...locale,
									lang: {
										...locale.lang,
										ok: t("common.apply")
									}
								}}
							/>
						</FormItem>
					</Col>
					<Col xs={24} sm={18} lg={18} xl={12}>
						<div className="table-filters-quick-filters">
							<FormItem label={t("common.quick_filters")} name="quickFilters" className="table-filters-quick-filters-buttons">
								<Radio.Group>
									<Radio.Button value="last_24_h" style={{ marginBottom: "8px" }}>
										{t("common.last_24_h")}
									</Radio.Button>
									<Radio.Button value="last_week" style={{ marginBottom: "8px" }}>
										{t("common.this_week")}
									</Radio.Button>
									<Radio.Button value="last_month" style={{ marginBottom: "8px" }}>
										{t("common.this_month")}
									</Radio.Button>
									<Radio.Button value="last_3_month" style={{ marginBottom: "8px" }}>
										{t("common.last_3_months")}
									</Radio.Button>
								</Radio.Group>
							</FormItem>
						</div>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to set betshop performance report filters */
	setBetshopPerformanceReportFilters: PropTypes.func,
	/** Redux state property, Betshop performance report filters */
	filters: PropTypes.object,
	/** Redux action to get betshop performance report */
	getBetshopPerformanceReport: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getBetshopPerformanceReport: () => {
		dispatch(getBetshopPerformanceReport());
	},
	setBetshopPerformanceReportFilters: (filters) => {
		dispatch(setBetshopPerformanceReportFilters(filters));
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.reports.betshopPerformance.filters,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
