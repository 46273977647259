import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GAME_CATEGORY, GAME_CHANNEL, GAME_LABEL_TYPE_FLAGS, GAME_LABEL_TYPE_MAPPER, SCHEDULED_GAME_TYPE } from "constants/game.constants.js";
import { arrayMove, binaryToFlags, getGamesFromConfig } from "utils/common.js";
import { hasPermission } from "utils/permissions.js";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import { Button } from "antd";
import Table from "components/common/table";
import { deleteSSBTGame, getEditingSSBTGames, saveSSBTGames, updateSSBTGame } from "store/actions/dashboard/ssbt/ssbt.action.js";
import moment from "moment/moment.js";
import { DATE_TIME_FORMAT } from "constants/date.constants.js";
import GameAddComponent from "components/dashboard/ssbt/edit/sections/games/game-add.component.jsx";
import EditGameModal from "components/dashboard/ssbt/edit/sections/games/editGameModal/index.jsx";
import { getGames } from "store/actions/dashboard/virtuals/games/games.action.js";

const GamesComponent = () => {
	const [currentRTPGameId, setCurrentRTPGameId] = useState(null);
	const [showAddPopup, setShowAddPopup] = useState(false);

	const dispatch = useDispatch();
	const routeParams = useParams();
	const { t } = useTranslation();

	const isLoading = useSelector((state) => state.ssbts.editingSSBT.gamesLoading);
	const games = useSelector((state) => state.ssbts.editingSSBT.games);
	const stateGames = useSelector((state) => state.games.games);

	const isModifyDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.MODIFY });

	const columns = [
		{
			title: t("pages.dashboard.games.name"),
			dataIndex: "name",
			sorter: false,
			className: "drag-visible"
		},
		{
			title: t("pages.dashboard.games.type"),
			dataIndex: "type",
			render: (value) => {
				const type = Object.values(SCHEDULED_GAME_TYPE).find((g) => g.value === value)?.type;
				return type ? t(`common.${type}`) : null;
			},
			sorter: false
		},
		{
			title: t("pages.dashboard.games.state"),
			dataIndex: "label",
			render: (label = []) =>
				binaryToFlags(GAME_LABEL_TYPE_FLAGS, label).reduce((p, v, i) => p + (i > 0 ? ", " : "") + GAME_LABEL_TYPE_MAPPER[v], ""),
			sorter: false
		}
	];

	const generateEditAction = () => {
		let key = null;

		if (hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.MODIFY })) {
			key = "edit";
		}

		if (!key) {
			return {};
		}

		return {
			[key]: {
				handler: (record) => setGameParams(record),
				title: t("pages.dashboard.ssbt.other_configs")
			}
		};
	};

	const setGameParams = (record) => {
		setCurrentRTPGameId(record.id);
	};

	const addGamesLength = useMemo(() => {
		const possibleGames = getGamesFromConfig(GAME_CATEGORY.SCHEDULED).map((t) => t.value);
		const available = [];
		const types = games.map((g) => g.type);

		possibleGames.forEach((p) => {
			if (!types.includes(p)) {
				available.push(p);
			}
		});

		return stateGames.filter((game) => available.includes(game.type)).length;
	}, [games, stateGames]);

	const loadFn = () => dispatch(getEditingSSBTGames(routeParams.id));

	useEffect(() => {
		setShowAddPopup(false);
	}, [games]);

	useEffect(() => {
		dispatch(getGames(false, true, GAME_CATEGORY.SCHEDULED, GAME_CHANNEL.RETAIL));
	}, []);

	return (
		<div className="dashboard-section-content">
			{hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.CREATE }) && (
				<div className="dashboard-section-buttons">
					<Button
						type="primary"
						htmlType="button"
						className="button vs--ml-8"
						onClick={() => setShowAddPopup(true)}
						disabled={addGamesLength === 0}
					>
						<span>{t("pages.dashboard.apikeys.add_new_game")}</span>
					</Button>
				</div>
			)}
			<Table
				loading={isLoading}
				columns={columns}
				data={games}
				loadFn={loadFn}
				total={games.length}
				isDisabled={(record) => isModifyDisabled || !record.enabled}
				isDraggable={() => !isModifyDisabled}
				noPagination={true}
				uniqueKey="id"
				draggable={
					isModifyDisabled
						? null
						: {
								onDragEnd: (oldIndex, newIndex) => {
									const updatedGames = arrayMove(games, oldIndex, newIndex);
									dispatch(saveSSBTGames(routeParams.id, updatedGames));
								}
							}
				}
				actions={{
					activate: isModifyDisabled
						? null
						: {
								isChecked: (record) => record.enabled,
								messageKey: "game",
								handler: (isChecked, record) => {
									dispatch(
										updateSSBTGame({
											enabled: isChecked,
											gameId: record.id,
											id: routeParams.id
										})
									);
								},
								disabled: (record) => !!record.disabledInfo,
								info: (record) =>
									!record.enabled && record.disableInfo ? (
										<>
											<p>
												{t("pages.dashboard.games.game_disabled_tooltip")}
												<br />
											</p>
											{record.disableInfo && record.disableInfo.disableFrom ? (
												<span>
													<b>{t("common.from")} : </b>
													{moment.utc(record.disableInfo.disableFrom).local().format(DATE_TIME_FORMAT)}
													<br />
												</span>
											) : null}
											{record.disableInfo && record.disableInfo.disableTo ? (
												<span>
													<b>{t("common.to")} : </b>
													{moment.utc(record.disableInfo.disableTo).local().format(DATE_TIME_FORMAT)}
													<br />
												</span>
											) : null}
											<span>
												<b>{t("pages.dashboard.games.reason")} : </b>
												{record.disableInfo.disableReason}
											</span>
										</>
									) : null
							},
					delete: hasPermission({ resource: PERMISSION_RESOURCE.TERMINAL_GAMES, action: PERMISSION_ACTION.DELETE })
						? {
								messageKey: "game",
								handler: (record) => dispatch(deleteSSBTGame(routeParams.id, record.id))
							}
						: null,
					...generateEditAction()
				}}
			/>

			{showAddPopup && <GameAddComponent onClose={() => setShowAddPopup(false)} onSuccess={loadFn} />}

			<EditGameModal isModalVisible={currentRTPGameId} gameId={currentRTPGameId} setIsModalVisible={setGameParams} />
		</div>
	);
};

export default GamesComponent;
