import Paths from "constants/path.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { PARTNER_TYPE } from "constants/partner.constants";
import { USER_ROLE, USER_ROLE_NAME } from "constants/user.constants";
import { binaryToFlags } from "utils/common";

const getMenuItems = (partnerTypes) => {
	const flagsOfPartnerTypeBinary = partnerTypes.map((t) => binaryToFlags(Object.values(PARTNER_TYPE), t));
	const hasOnline = flagsOfPartnerTypeBinary.some((t) => t.includes(PARTNER_TYPE.ONLINE));
	const hasRetail = flagsOfPartnerTypeBinary.some((t) => t.includes(PARTNER_TYPE.RETAIL));
	const hasSSBT = flagsOfPartnerTypeBinary.some((t) => t.includes(PARTNER_TYPE.SSBT));

	return [
		{
			id: "f",
			icon: "icon-dashboard",
			title: "pages.dashboard.menu.dashboard",
			url: Paths.DASHBOARD_STATISTICS,
			permission: [
				{ resource: PERMISSION_RESOURCE.DASHBOARD_ONLINE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.DASHBOARD_RETAIL, action: PERMISSION_ACTION.VIEW }
			],
			match: [Paths.DASHBOARD_STATISTICS]
		},
		{
			id: "1",
			icon: "icon-virtual-games",
			title: "pages.dashboard.menu.virtuals",
			permission: { resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW },
			items: [
				{
					id: "11",
					title: "pages.dashboard.menu.virtual_scheduled_games",
					url: Paths.DASHBOARD_SCHEDULED_GAMES,
					permission: { resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_SCHEDULED_GAMES, Paths.DASHBOARD_SCHEDULED_GAMES_EDIT]
				},
				{
					id: "12",
					title: "pages.dashboard.menu.virtual_instant_games",
					url: Paths.DASHBOARD_INSTANT_GAMES,
					permission: { resource: PERMISSION_RESOURCE.GAME, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_INSTANT_GAMES, Paths.DASHBOARD_INSTANT_GAMES_EDIT]
				}
			]
		},
		{
			id: "h",
			icon: "icon-menu-bonuses",
			title: "pages.dashboard.menu.bonuses",
			permission: [
				{ resource: PERMISSION_RESOURCE.BONUS, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "h1",
					title: "pages.dashboard.menu.bonuses_jackpot",
					url: Paths.DASHBOARD_BONUSES_JACKPOT,
					permission: { resource: PERMISSION_RESOURCE.BONUS_JACKPOT, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_BONUSES_JACKPOT, Paths.DASHBOARD_BONUSES_JACKPOT_EDIT]
				},
				{
					id: "h2",
					title: "pages.dashboard.menu.bonuses_standard",
					url: Paths.DASHBOARD_BONUSES_STANDARD,
					permission: { resource: PERMISSION_RESOURCE.BONUS_STANDARD, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_BONUSES_STANDARD, Paths.DASHBOARD_BONUSES_STANDARD_EDIT]
				}
			]
		},
		{
			id: "8",
			icon: "icon-stream",
			title: "pages.dashboard.menu.streams",
			url: Paths.DASHBOARD_STREAMS,
			permission: { resource: PERMISSION_RESOURCE.STREAMS, action: PERMISSION_ACTION.VIEW },
			match: [Paths.DASHBOARD_STREAMS, Paths.DASHBOARD_STREAMS_EDIT]
		},
		{
			id: "2",
			icon: "icon-partners",
			url: Paths.DASHBOARD_PARTNERS,
			title: "pages.dashboard.menu.partners",
			permission: { resource: PERMISSION_RESOURCE.PARTNER, action: PERMISSION_ACTION.VIEW },
			match: [Paths.DASHBOARD_PARTNERS, Paths.DASHBOARD_PARTNERS_EDIT]
		},
		{
			id: "b",
			icon: "icon-betslips",
			title: "pages.dashboard.menu.betslips",
			permission: [
				{ resource: PERMISSION_RESOURCE.BETSLIPS_ONLINE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BETSLIPS_RETAIL, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "b1",
					title: "pages.dashboard.menu.online",
					url: Paths.DASHBOARD_BETSLIPS_ONLINE,
					permission: { resource: PERMISSION_RESOURCE.BETSLIPS_ONLINE, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_BETSLIPS_ONLINE]
				},
				{
					id: "b2",
					title: "pages.dashboard.menu.retail",
					url: Paths.DASHBOARD_BETSLIPS_RETAIL,
					permission: { resource: PERMISSION_RESOURCE.BETSLIPS_RETAIL, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_BETSLIPS_RETAIL]
				}
			]
		},
		{
			id: "3",
			icon: "icon-online",
			title: "pages.dashboard.menu.online",
			permission: [
				{ resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW }
			],
			disabled: !hasOnline,
			items: [
				{
					id: "31",
					title: "pages.dashboard.menu.projects",
					url: Paths.DASHBOARD_ONLINE_APIKEYS,
					permission: { resource: PERMISSION_RESOURCE.APIKEY, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_ONLINE_APIKEYS, Paths.DASHBOARD_ONLINE_APIKEYS_EDIT]
				},
				{
					id: "32",
					title: "pages.dashboard.menu.players",
					url: Paths.DASHBOARD_ONLINE_PLAYERS,
					permission: { resource: PERMISSION_RESOURCE.PLAYER, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_ONLINE_PLAYERS, Paths.DASHBOARD_ONLINE_PLAYERS_VIEW]
				}
			]
		},
		{
			id: "d",
			icon: "icon-events",
			title: "pages.dashboard.menu.events",
			permission: { resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.VIEW },
			items: [
				{
					id: "d1",
					title: "pages.dashboard.events.tabs.online_events",
					url: Paths.DASHBOARD_EVENTS_ONLINE,
					permission: { resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_EVENTS_ONLINE],
					disabled: !hasOnline
				},
				{
					id: "d2",
					title: "pages.dashboard.events.tabs.retail_events",
					url: Paths.DASHBOARD_EVENTS_RETAIL,
					permission: { resource: PERMISSION_RESOURCE.EVENTS, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_EVENTS_RETAIL],
					disabled: !hasRetail
				}
			]
		},
		{
			id: "6",
			icon: "icon-retail",
			title: "pages.dashboard.menu.retail",
			permission: [
				{ resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW }
			],
			disabled: !hasRetail,
			items: [
				{
					id: "61",
					title: "pages.dashboard.menu.boxes",
					url: Paths.DASHBOARD_BOXES,
					permission: { resource: PERMISSION_RESOURCE.BOX, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_BOXES, Paths.DASHBOARD_BOXES_EDIT]
				},
				{
					id: "62",
					title: "pages.dashboard.menu.betshops",
					url: Paths.DASHBOARD_RETAIL_BETSHOPS,
					permission: { resource: PERMISSION_RESOURCE.BETSHOP, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_RETAIL_BETSHOPS, Paths.DASHBOARD_RETAIL_BETSHOPS_EDIT, Paths.DASHBOARD_RETAIL_BETSHOPS_VIEW]
				},
				{
					id: "63",
					title: "pages.dashboard.menu.cashier_management",
					items: [
						{
							id: "631",
							url: Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS,
							title: "pages.dashboard.menu.cashiers",
							match: [Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS, Paths.DASHBOARD_RETAIL_CASHIER_MANAGEMENT_CASHIERS_EDIT]
						}
					],
					permission: { resource: PERMISSION_RESOURCE.CASHIER, action: PERMISSION_ACTION.VIEW }
				}
			]
		},
		{
			id: "ssbt",
			icon: "icon-ssbt",
			url: Paths.DASHBOARD_SSBT,
			title: "pages.dashboard.menu.ssbt",
			permission: { resource: PERMISSION_RESOURCE.TERMINAL, action: PERMISSION_ACTION.VIEW },
			disabled: !hasSSBT,
			match: [Paths.DASHBOARD_SSBT, Paths.DASHBOARD_SSBT_EDIT]
		},
		{
			id: "a",
			icon: "icon-transactions",
			url: Paths.DASHBOARD_TRANSACTIONS,
			title: "pages.dashboard.menu.failed_transactions",
			permission: { resource: PERMISSION_RESOURCE.FAILED_TRANSACTION, action: PERMISSION_ACTION.VIEW },
			match: [Paths.DASHBOARD_TRANSACTIONS]
		},

		{
			id: "i",
			icon: "icon-risk-management",
			title: "pages.dashboard.menu.risk_management",
			permission: [
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "i1",
					title: "pages.dashboard.menu.bet_anomalies",
					url: Paths.DASHBOARD_RISK_MANAGEMENT_BET_ANOMALIES,
					permission: [
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_HISTORY, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BET_ANOMALIES_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW }
					],
					match: [Paths.DASHBOARD_RISK_MANAGEMENT_BET_ANOMALIES]
				},
				{
					id: "i2",
					title: "pages.dashboard.menu.player_suspicious_activities",
					url: Paths.DASHBOARD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES,
					permission: [
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_HISTORY, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW }
					],
					match: [Paths.DASHBOARD_RISK_MANAGEMENT_PLAYER_SUSPICIOUS_ACTIVITIES]
				},
				{
					id: "i3",
					title: "pages.dashboard.menu.big_multipliers",
					url: Paths.DASHBOARD_RISK_MANAGEMENT_BIG_MULTIPLIERS,
					permission: [
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_HISTORY, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.RISK_MANAGEMENT_BIG_MULTIPLIERS_CONFIGURATIONS, action: PERMISSION_ACTION.VIEW }
					],
					match: [Paths.DASHBOARD_RISK_MANAGEMENT_BIG_MULTIPLIERS]
				}
			]
		},

		{
			id: "4",
			icon: "icon-user-management",
			title: "pages.dashboard.menu.user_management",
			permission: [
				{ resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "41",
					title: "pages.dashboard.menu.users",
					url: Paths.DASHBOARD_USERMANAGEMENT_USERS,
					permission: { resource: PERMISSION_RESOURCE.USER, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_USERMANAGEMENT_USERS, Paths.DASHBOARD_USERMANAGEMENT_USERS_EDIT]
				},
				{
					id: "42",
					title: "pages.dashboard.menu.access_managers",
					url: Paths.ACCESS_MANAGERS,
					permission: { resource: PERMISSION_RESOURCE.ACCESS_MANAGER, action: PERMISSION_ACTION.VIEW },
					match: [Paths.ACCESS_MANAGERS, Paths.ACCESS_MANAGERS_EDIT]
				},
				{
					id: "43",
					title: "pages.dashboard.menu.permission_groups",
					url: Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS,
					permission: { resource: PERMISSION_RESOURCE.PERMISSION_GROUP, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS, Paths.DASHBOARD_USERMANAGEMENT_PERMISSION_GROUPS_EDIT]
				},
				{
					id: "44",
					title: "pages.dashboard.menu.permissions",
					url: Paths.DASHBOARD_USERMANAGEMENT_PERMISSIONS,
					permission: { resource: PERMISSION_RESOURCE.PERMISSION, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_USERMANAGEMENT_PERMISSIONS]
				},
				{
					id: "45",
					title: "pages.dashboard.menu.permissions_requests",
					url: Paths.PERMISSION_REQUESTS,
					match: [Paths.PERMISSION_REQUESTS],
					permission: { resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW }
				}
			]
		},
		{
			id: "e",
			icon: "icon-reports",
			title: "pages.dashboard.menu.reports",
			permission: [
				{ resource: PERMISSION_RESOURCE.REPORTS_PROJECT_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.REPORTS_PARTNER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "e1",
					title: "pages.dashboard.menu.projects_performance",
					url: Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE,
					permission: { resource: PERMISSION_RESOURCE.REPORTS_PROJECT_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_REPORTS_PROJECTS_PERFORMANCE]
				},
				{
					id: "e2",
					title: "pages.dashboard.menu.betshops_performance",
					url: Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE,
					permission: { resource: PERMISSION_RESOURCE.REPORTS_BETSHOP_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_REPORTS_BETSHOPS_PERFORMANCE]
				},
				{
					id: "e3",
					title: "pages.dashboard.menu.partner_online_report",
					url: Paths.DASHBOARD_REPORTS_PARTNER_ONLINE,
					permission: { resource: PERMISSION_RESOURCE.REPORTS_PARTNER_PERFORMANCE, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_REPORTS_PARTNER_ONLINE]
				}
			]
		},
		{
			id: "7",
			icon: "icon-responseRate",
			title: "pages.dashboard.menu.users_feedback",
			permission: [{ resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.VIEW }],
			items: [
				{
					id: "71",
					title: "pages.dashboard.menu.csat",
					url: Paths.DASHBOARD_USERS_FEEDBACK_CSAT,
					permission: { resource: PERMISSION_RESOURCE.SURVEYS, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_USERS_FEEDBACK_CSAT, Paths.DASHBOARD_USERS_FEEDBACK_CSAT_EDIT]
				}
			]
		},
		{
			id: "c",
			icon: "icon-userLogs",
			url: Paths.DASHBOARD_USER_LOGS,
			title: "pages.dashboard.menu.user_logs",
			permission: { resource: PERMISSION_RESOURCE.USER_LOGS, action: PERMISSION_ACTION.VIEW },
			match: [Paths.DASHBOARD_USER_LOGS]
		},
		{
			id: "9",
			icon: "icon-cms",
			title: "pages.dashboard.menu.cms",
			permission: [
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.BETSHOP_HOTKEYS, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "91",
					title: "pages.dashboard.menu.translations",
					url: Paths.DASHBOARD_CMS_TRANSLATIONS,
					match: [Paths.DASHBOARD_CMS_TRANSLATIONS],
					permission: [
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_PARTNER, action: PERMISSION_ACTION.VIEW }
					]
				},
				{
					id: "92",
					title: "pages.dashboard.menu.live_monitor_texts",
					url: Paths.DASHBOARD_CMS_LIVE_MONITOR_TRANSLATIONS,
					match: [Paths.DASHBOARD_CMS_LIVE_MONITOR_TRANSLATIONS],
					permission: { resource: PERMISSION_RESOURCE.TRANSLATIONS_LIVE_MONITOR, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "93",
					title: "pages.dashboard.menu.rules",
					url: Paths.DASHBOARD_CMS_RULES,
					match: [Paths.DASHBOARD_CMS_RULES, Paths.DASHBOARD_CMS_SCHEDULED_RULES_EDIT, Paths.DASHBOARD_CMS_INSTANT_RULES_EDIT],
					permission: [
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_SYSTEM, action: PERMISSION_ACTION.VIEW },
						{ resource: PERMISSION_RESOURCE.TRANSLATIONS_RULES, action: PERMISSION_ACTION.VIEW }
					]
				},
				{
					id: "94",
					title: "pages.dashboard.menu.betshop_tickets",
					url: Paths.DASHBOARD_CMS_TICKETS,
					match: [Paths.DASHBOARD_CMS_TICKETS],
					permission: { resource: PERMISSION_RESOURCE.BETSHOP_TICKETS, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "95",
					title: "pages.dashboard.menu.hotkeys",
					url: Paths.DASHBOARD_CMS_HOTKEYS,
					match: [Paths.DASHBOARD_CMS_HOTKEYS],
					permission: { resource: PERMISSION_RESOURCE.BETSHOP_HOTKEYS, action: PERMISSION_ACTION.VIEW }
				}
			]
		},
		{
			id: "g",
			icon: "icon-settings",
			title: "pages.dashboard.menu.settings",
			permission: [
				{ resource: PERMISSION_RESOURCE.SETTINGS_MAINTENANCE_MODE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.SETTINGS_LANGUAGES, action: PERMISSION_ACTION.VIEW }
			],
			items: [
				{
					id: "g1",
					title: "pages.dashboard.menu.currencies",
					url: Paths.DASHBOARD_SETTINGS_CURRENCIES,
					match: [Paths.DASHBOARD_SETTINGS_CURRENCIES],
					permission: { resource: PERMISSION_RESOURCE.SETTINGS_CURRENCIES, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "g2",
					url: Paths.DASHBOARD_SETTINGS_LANGUAGES,
					title: "pages.dashboard.menu.languages",
					permission: { resource: PERMISSION_RESOURCE.SETTINGS_LANGUAGES, action: PERMISSION_ACTION.VIEW },
					match: [Paths.DASHBOARD_SETTINGS_LANGUAGES]
				},
				{
					id: "g3",
					title: "pages.dashboard.menu.maintenance_mode",
					url: Paths.DASHBOARD_SETTINGS_MAINTENANCEMODE,
					match: [Paths.DASHBOARD_SETTINGS_MAINTENANCEMODE],
					permission: { resource: PERMISSION_RESOURCE.SETTINGS_MAINTENANCE_MODE, action: PERMISSION_ACTION.VIEW }
				}
			]
		},
		{
			id: "5",
			icon: "icon-developer",
			title: "pages.dashboard.menu.developer_space",
			permission: [
				{ resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW },
				{ resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAMS, action: PERMISSION_ACTION.VIEW }
				//#region unused functionality
				// { resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAM_SERVERS, action: PERMISSION_ACTION.VIEW }
				//#endregion
			],
			items: [
				{
					id: "50",
					title: "pages.dashboard.menu.operators",
					url: Paths.DASHBOARD_DEVELOPER_OPERATORS,
					match: [Paths.DASHBOARD_DEVELOPER_OPERATORS],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "51",
					title: "pages.dashboard.menu.logs",
					items: [
						{
							id: "511",
							url: Paths.DASHBOARD_DEVELOPER_ERRORS,
							title: "pages.dashboard.menu.logs_errors",
							match: [Paths.DASHBOARD_DEVELOPER_ERRORS, Paths.DASHBOARD_DEVELOPER_ERROR_DETAILS]
						},
						{
							id: "512",
							url: Paths.DASHBOARD_DEVELOPER_REQUESTS,
							title: "pages.dashboard.menu.logs_requests",
							match: [Paths.DASHBOARD_DEVELOPER_REQUESTS]
						}
					],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "52",
					title: "pages.dashboard.menu.jobs",
					url: Paths.DASHBOARD_DEVELOPER_JOBS,
					match: [Paths.DASHBOARD_DEVELOPER_JOBS],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "53",
					title: "pages.dashboard.menu.service_monitoring",
					items: [],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "54",
					title: "pages.dashboard.menu.streams",
					url: Paths.DASHBOARD_DEVELOPER_STREAMS,
					match: [Paths.DASHBOARD_DEVELOPER_STREAMS],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAMS, action: PERMISSION_ACTION.VIEW }
				},
				//#region unused functionality
				// {
				// 	id: '55',
				// 	title: 'pages.dashboard.menu.streamServers',
				// 	url: Paths.DASHBOARD_DEVELOPER_STREAM_SERVERS,
				// 	match: [Paths.DASHBOARD_DEVELOPER_STREAM_SERVERS],
				// 	permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_STREAM_SERVERS, action: PERMISSION_ACTION.VIEW }
				// },
				//#endregion
				{
					id: "56",
					title: "pages.dashboard.menu.postDeploymentActions",
					url: Paths.DASHBOARD_DEVELOPER_POST_DEPLOYMENT_ACTIONS,
					match: [Paths.DASHBOARD_DEVELOPER_POST_DEPLOYMENT_ACTIONS],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				},
				{
					id: "57",
					title: "pages.dashboard.menu.betCorrection",
					url: Paths.DASHBOARD_DEVELOPER_BETSLIP_CORRECTION,
					match: [Paths.DASHBOARD_DEVELOPER_BETSLIP_CORRECTION],
					permission: { resource: PERMISSION_RESOURCE.DEVELOPMENT_SPACE, action: PERMISSION_ACTION.VIEW }
				}
			]
		}
	];
};

export default getMenuItems;
