import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
const { Item: FormItem } = Form;
import Table from "components/common/table";
import NumericInput from "components/common/numericInput";
import { numberTransform } from "utils/common";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import rtpType from "types/rtp/rtp.type";

/** Game Edit Page Default RTP Tab Common RTP Component */
const LuckySixRTP = ({ isLoading, rtp, getRTPs }) => {
	const { t } = useTranslation();

	const [data, setData] = useState([]);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.GAME_RTP, action: PERMISSION_ACTION.MODIFY });

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		if (!rtp.rtPs.length) {
			return;
		}
		const d = Array.from({ length: 10 }).map((_, i) => ({ id: i }));

		rtp.rtPs
			.filter((r) => r.name?.startsWith("6"))
			.forEach((r) => {
				const rowIndex = (r.name.split("-")[1] - 6) % 10;
				const colIndex = r.name.split("-")[1] > 25 ? 2 : r.name.split("-")[1] > 15 ? 1 : 0;

				d[rowIndex]["col_" + colIndex] = { ...r };
			});

		setData(d);
	}, [rtp.rtPs]);

	/** Calculate margin
	 * @function
	 * @param {Object} value
	 * @returns {number}
	 * @memberOf LuckySixRTP
	 */

	/** Columns of table */
	const columns = Array.from(Array(3).keys()).map((column) => ({
		title: column === 0 ? "" : column,
		dataIndex: "col_" + column,
		sorter: false,
		render: (value, record) => {
			if (!value?.enabled) {
				return "";
			}

			return (
				<div className="vs--flex vs--align-center vs--flex-equal vs--lucky-six-rtps">
					<span>{value.name.split("-")[1]}</span>
					<FormItem
						className="inline-form-item-control table-form-control ant-table-small-input"
						style={{ marginLeft: 0 }}
						name={["rtPs", value.name + "_" + value.period, "margin"]}
						rules={[
							{ required: true, message: t("validation.field_required") },
							{
								type: "number",
								min: 1,
								message: t("validation.must_be_more").replace("%X%", 1),
								transform: numberTransform
							},
							{
								type: "number",
								max: 10000,
								message: t("validation.must_be_less").replace("%X%", 10000),
								transform: numberTransform
							}
						]}
					>
						<NumericInput style={{ maxWidth: "198px" }} disabled={isDisabled} />
					</FormItem>
				</div>
			);
		},
		align: "center"
	}));

	return (
		<div className="dashboard-game-default-rtp">
			<div className="dashboard-game-default-rtp-header vs--flex vs--align-center vs--justify-center vs--font-medium">
				{t("pages.dashboard.games.paytable")}
			</div>
			<Table
				showHeader={false}
				loading={isLoading}
				columns={columns}
				data={data}
				loadFn={() => getRTPs()}
				noPagination={true}
				uniqueKey="id"
				nonFixed={true}
				className="ant-table-small"
				tableLayout="fixed"
				isDisabled={() => isDisabled}
				bordered
			/>
		</div>
	);
};

/** LuckySixRTP propTypes
 * PropTypes
 */
LuckySixRTP.propTypes = {
	/** Is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Function to get rtps */
	getRTPs: PropTypes.func,
	/** Redux state, represents the rtp data   */
	rtp: rtpType
};

export default LuckySixRTP;
