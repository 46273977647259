import { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Select, Input } from "antd";
import { setUserLogsFilters, getUserLogs, getUserLogResources } from "store/actions/dashboard/userLogs/userLogs.action";
import FiltersWrapper from "components/common/filters";
import AutoComplete from "components/common/autoComplete";
import useAutosuggestion from "hooks/useAutosuggestion";
import { USER_LOGS_ACTION_TYPE } from "constants/userLog.constants";
import { MONTHS, YEARS } from "constants/common.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autoSuggestion.constants";
const { Item: FormItem } = Form;

/** User Logs Page Filters Component */
const Filters = ({ setUserLogsFilters, getUserLogs, getUserLogResources, resources, filters, globalPartnerId }) => {
	const { t } = useTranslation();
	const userNames = useAutosuggestion(AUTOSUGGESTION_TYPE.USER_NAMES, [globalPartnerId], { disableAction: () => !globalPartnerId });
	useEffect(() => {
		getUserLogResources();
	}, [globalPartnerId]);

	const filtersList = [
		{ name: "userNameOrId", title: t("pages.dashboard.userLogs.user_name_or_ID") },
		{ name: "action", title: t("pages.dashboard.userLogs.action") },
		{ name: "resourceId", title: t("pages.dashboard.userLogs.resource_id") },
		{
			name: "resource",
			title: t("pages.dashboard.userLogs.resource"),
			values: [{ title: t("common.all"), value: "" }, ...Object.keys(resources).map((res) => ({ title: resources[res], value: res }))]
		},
		{
			name: "actionType",
			title: t("pages.dashboard.userLogs.actionType"),
			values: [
				{ title: t("common.all"), value: "" },
				{ title: t("pages.dashboard.userLogs.actionType_1"), value: USER_LOGS_ACTION_TYPE.CREATE },
				{ title: t("pages.dashboard.userLogs.actionType_2"), value: USER_LOGS_ACTION_TYPE.DELETE },
				{ title: t("pages.dashboard.userLogs.actionType_3"), value: USER_LOGS_ACTION_TYPE.ADD },
				{ title: t("pages.dashboard.userLogs.actionType_4"), value: USER_LOGS_ACTION_TYPE.REMOVE },
				{ title: t("pages.dashboard.userLogs.actionType_5"), value: USER_LOGS_ACTION_TYPE.EDIT },
				{ title: t("pages.dashboard.userLogs.actionType_6"), value: USER_LOGS_ACTION_TYPE.EXPORT }
			]
		},
		{ name: "month", title: t("common.month"), values: Object.values(MONTHS).map((m) => ({ title: t("months.month_" + m), value: m })) },
		{ name: "year", title: t("common.year"), values: YEARS.map((y) => ({ title: y, value: y })) }
	];

	return (
		<FiltersWrapper filtersName="userLogs" loadFn={getUserLogs} setFiltersFn={setUserLogsFilters} filters={filters} filtersList={filtersList}>
			<Fragment>
				<Row gutter={[16, 0]}>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.userLogs.user_name_or_ID")} name="userNameOrId">
							<AutoComplete placeholder={`${t("common.start_typing")} ${t("pages.dashboard.userLogs.user_name_or_ID")}`} items={userNames.map((b) => b.value)} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.userLogs.action")} name="action">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.userLogs.action")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.userLogs.resource_id")} name="resourceId">
							<Input placeholder={`${t("common.enter")} ${t("pages.dashboard.userLogs.resource_id")}`} />
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.userLogs.resource")} name="resource">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								{Object.keys(resources).map((res) => (
									<Select.Option value={res} key={res}>
										{resources[res]}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("pages.dashboard.userLogs.actionType")} name="actionType">
							<Select suffixIcon={<i className="icon-down" />}>
								<Select.Option value="">{t("common.all")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.CREATE}>{t("pages.dashboard.userLogs.actionType_1")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.DELETE}>{t("pages.dashboard.userLogs.actionType_2")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.ADD}>{t("pages.dashboard.userLogs.actionType_3")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.REMOVE}>{t("pages.dashboard.userLogs.actionType_4")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.EDIT}>{t("pages.dashboard.userLogs.actionType_5")}</Select.Option>
								<Select.Option value={USER_LOGS_ACTION_TYPE.EXPORT}>{t("pages.dashboard.userLogs.actionType_6")}</Select.Option>
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.month")} name="month">
							<Select suffixIcon={<i className="icon-down" />}>
								{Object.values(MONTHS).map((m) => (
									<Select.Option value={m} key={m}>
										{t("months.month_" + m)}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
					<Col xs={24} sm={12} lg={6} xl={4}>
						<FormItem label={t("common.year")} name="year">
							<Select suffixIcon={<i className="icon-down" />}>
								{YEARS.map((y) => (
									<Select.Option value={y} key={y}>
										{y}
									</Select.Option>
								))}
							</Select>
						</FormItem>
					</Col>
				</Row>
			</Fragment>
		</FiltersWrapper>
	);
};

/** Filters propTypes
 * PropTypes
 */
Filters.propTypes = {
	/** Redux action to get user logs */
	getUserLogs: PropTypes.func,
	/** Redux action to set user logs filters */
	setUserLogsFilters: PropTypes.func,
	/** Redux state property, user logs filters */
	filters: PropTypes.object,
	/** Redux state property, user logs resources */
	resources: PropTypes.object,
	/** Redux action to get user logs resources */
	getUserLogResources: PropTypes.func,
	/** Redux state property, represents global partner id */
	globalPartnerId: PropTypes.string
};

const mapDispatchToProps = (dispatch) => ({
	getUserLogs: () => {
		dispatch(getUserLogs());
	},
	setUserLogsFilters: (filters) => {
		dispatch(setUserLogsFilters(filters));
	},
	getUserLogResources: () => {
		dispatch(getUserLogResources());
	}
});

const mapStateToProps = (state) => {
	return {
		filters: state.userLogs.filters,
		resources: state.userLogs.resources,
		globalPartnerId: state.partner.globalPartnerId
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
