import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spin from "antd/lib/spin";
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import Switch from "antd/lib/switch";
import Button from "antd/lib/button";

const { Item: FormItem } = Form;
import { useTranslation } from "react-i18next";

import {
	getPartnerTerminalConfigs,
	savePartnerTerminalConfigs
} from "store/actions/dashboard/partners/terminalSettings.action";

import { copyToClipboard, isNullish } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";

import { URL_REGEX } from "constants/regex.constants";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

const FORM_FIELDS = {
	ONLINE_PREFIX: "terminalOnlineSettings",
	RETAIL_PREFIX: "terminalRetailSettings",
	ONLINE_MODE_ENABLED: "onlineModeEnabled",
	RETAIL_MODE_ENABLED: "retailModeEnabled",
	SECRET_KEY: "secret",
	ENDPOINT_URL: "endpointUrl"
}

const getTerminalSettings = (state) => state.partner.terminalSettings;
const getIsTerminalSettingsLoading = (state) => state.partner.isLoading;
const getIsTerminalSettingsSaving = (state) => state.partner.isSaving;

/** Terminal Tab Component */
const TerminalSettings = ({ onTabChange }) => {
	const isLoading = useSelector(getIsTerminalSettingsLoading);
	const isSaving = useSelector(getIsTerminalSettingsSaving);
	const terminalSettings = useSelector(getTerminalSettings);

	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;

	const [isFormTouched, setIsFormTouched] = useState(false);

	const canEdit = hasPermission({ resource: PERMISSION_RESOURCE.PARTNER_TERMINAL_CONFIGURATION, action: PERMISSION_ACTION.MODIFY });

	const dispatch = useDispatch();

	const handleForm = () => {
		validateFields()
			.then((data) => {
				const { onlineModeEnabled, retailModeEnabled, terminalOnlineSettings, terminalRetailSettings } = data;

				const getSettings = (settings, isEnabled) => {
					if (!isEnabled || !settings.endpointUrl) {
						return undefined;
					}

					const { secret, ...rest } = settings;
					return rest;
				};

				const onlineSettings = getSettings(terminalOnlineSettings, onlineModeEnabled);
				const retailSettings = getSettings(terminalRetailSettings, retailModeEnabled);

				dispatch(savePartnerTerminalConfigs({
					terminalOnlineSettings: onlineSettings,
					terminalRetailSettings: retailSettings,
					onlineModeEnabled,
					retailModeEnabled
				}));
				setIsFormTouched(false);
			})
			.catch(Function.prototype);
	};

	useEffect(() => {
		dispatch(getPartnerTerminalConfigs());
	}, []);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		setFieldsValue({ ...terminalSettings });
	}, [terminalSettings]);

	useEffect(() => {
		onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Spin spinning={isLoading} wrapperClassName="form-spin vs--pl-12 vs--pr-12 vs--pt-12">
			<Form
				colon={false}
				form={formInstance}
				requiredMark={false}
				layout="vertical"
				onValuesChange={(changed, formValues) => {
					setIsFormTouched(isFormChanged(
						formValues,
						terminalSettings
					))
				}}
			>
				<div className="dashboard-section-content">
					<div className="terminal-settings vs--flex vs--flex-col">
						<Row>
							<Col span={12}>
								<Card
									className="terminal-settings-endpoint-details"
									title={(
										<Row align="middle">
											<div className="inline-form-item form-switcher form-item-without-margin">
												<FormItem name={FORM_FIELDS.RETAIL_MODE_ENABLED} valuePropName="checked" className="ant-row">
													<Switch
														disabled={!canEdit}
														onChange={(value) => validateFields([[FORM_FIELDS.RETAIL_PREFIX, FORM_FIELDS.ENDPOINT_URL]])}
													/>
												</FormItem>
												<label className="vs--ml-4">{t("pages.dashboard.partners.terminal_settings.retailIntegrationDetails")}</label>
											</div>
										</Row>
									)}
								>
									<Row gutter={[16, 0]} className="terminal-settings-endpoint-details-content">
										<Col span={12}>
											<FormItem
												label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
												name={[FORM_FIELDS.RETAIL_PREFIX, FORM_FIELDS.SECRET_KEY]}
											>
												<Input
													disabled
													placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
													className="form-input-with-copy"
												/>
											</FormItem>
											<Tooltip title={t("common.copy") + " " + t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")} getPopupContainer={() => document.body}>
												<div className="form-copy-button" onClick={() => copyToClipboard(terminalSettings.terminalRetailSettings.secret)}>
													<i className="icon-copy"></i>
												</div>
											</Tooltip>
										</Col>
										<FormItem shouldUpdate noStyle>
											{({ getFieldValue }) => (
												<Col span={12}>
													<FormItem
														label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")} *`}
														name={[FORM_FIELDS.RETAIL_PREFIX, FORM_FIELDS.ENDPOINT_URL]}
														rules={[
															{
																validator: (_, value) => {
																	if (!getFieldValue(FORM_FIELDS.RETAIL_MODE_ENABLED)) {
																		return Promise.resolve();
																	}
																	if (isNullish(value) || value === "") {
																		return Promise.reject(t("validation.field_required"));
																	}
																	if (!URL_REGEX.test(value)) {
																		return Promise.reject(t("validation.url_format"));
																	}
																	return Promise.resolve();
																}
															}
														]}
													>
														<Input
															disabled={!getFieldValue(FORM_FIELDS.RETAIL_MODE_ENABLED)}
															placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")}`}
														/>
													</FormItem>
												</Col>
											)}
										</FormItem>
									</Row>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Card
									className="terminal-settings-endpoint-details"
									title={(
										<Row align="middle">
											<div className="inline-form-item form-switcher form-item-without-margin">
												<FormItem name={FORM_FIELDS.ONLINE_MODE_ENABLED} valuePropName="checked" className="ant-row">
													<Switch
														disabled={!canEdit}
														onChange={(value) => validateFields([[FORM_FIELDS.ONLINE_PREFIX, FORM_FIELDS.ENDPOINT_URL]])}
													/>
												</FormItem>
												<label className="vs--ml-4">{t("pages.dashboard.partners.terminal_settings.onlineIntegrationDetails")}</label>
											</div>
										</Row>
									)}
								>
									<Row gutter={[16, 0]} className="terminal-settings-endpoint-details-content">
										<Col span={12}>
											<FormItem
												label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
												name={[FORM_FIELDS.ONLINE_PREFIX, FORM_FIELDS.SECRET_KEY]}
											>
												<Input
													disabled
													placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")}`}
													className="form-input-with-copy"
												/>
											</FormItem>
											<Tooltip title={t("common.copy") + " " + t("pages.dashboard.partners.terminal_settings.terminal_configs.secret_key")} getPopupContainer={() => document.body}>
												<div className="form-copy-button" onClick={() => copyToClipboard(terminalSettings.terminalOnlineSettings.secret)}>
													<i className="icon-copy"></i>
												</div>
											</Tooltip>
										</Col>
										<FormItem shouldUpdate noStyle>
											{({ getFieldValue }) => (
												<Col span={12}>
													<FormItem
														label={`${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")} *`}
														name={[FORM_FIELDS.ONLINE_PREFIX, FORM_FIELDS.ENDPOINT_URL]}
														rules={[
															{
																validator: (_, value) => {
																	if (!getFieldValue(FORM_FIELDS.ONLINE_MODE_ENABLED)) {
																		return Promise.resolve();
																	}
																	if (isNullish(value) || value === "") {
																		return Promise.reject(t("validation.field_required"));
																	}
																	if (!URL_REGEX.test(value)) {
																		return Promise.reject(t("validation.url_format"));
																	}
																	return Promise.resolve();
																}
															}
														]}
													>
														<Input
															disabled={!getFieldValue(FORM_FIELDS.ONLINE_MODE_ENABLED)}
															placeholder={`${t("common.enter")} ${t("pages.dashboard.partners.terminal_settings.terminal_configs.endpoint_url")}`}
														/>
													</FormItem>
												</Col>
											)}
										</FormItem>
									</Row>
								</Card>
							</Col>
						</Row>
					</div>
					<FormItem className="button-container">
						<Button
							loading={isSaving}
							type="primary"
							htmlType="submit"
							className="button"
							onClick={handleForm}
							disabled={!canEdit || !isFormTouched}
						>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				</div>
			</Form>
		</Spin>
	);
};

export default TerminalSettings;
