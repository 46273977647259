const CircleColor = ({ color }) => {
	return (
		<div className="vs--lucky-six-select-color">
			<div className={`vs--lucky-six-select-color-button vs--lucky-six-select-color-button-${color}`}>
				<div className={`vs--lucky-six-select-color-button-inner`}></div>
			</div>
		</div>
	);
};

export default CircleColor;
