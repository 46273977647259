import { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col, Spin } from "antd";
import Breadcrumbs from "components/common/breadcrumbs";
import { GAME_STREAM_CONFIGURATION_TYPE } from "constants/game.constants";
import VideoPlayer from "components/common/videoPlayer";

import { getStreams } from "store/actions/dashboard/developer/streams/streams.action";

const onResizeChangeVideosHeight = () => {
	const videoContainers = document.getElementsByClassName("vs--video-container");
	Array.prototype.forEach.call(videoContainers, (videoContainer, i) => {
		let offsetHeight = (videoContainer.offsetWidth / 16) * 9 + "px";
		videoContainer.style.minHeight = offsetHeight;
		videoContainer.style.height = offsetHeight;
	});
};

/** Streams Page Component */
const StreamsComponent = ({ streams, isLoading, getStreams, ...props }) => {
	const { t } = useTranslation();

	useEffect(() => {
		getStreams();
	}, []);

	useEffect(() => {
		onResizeChangeVideosHeight();
		window.addEventListener("resize", onResizeChangeVideosHeight);
		return () => {
			window.removeEventListener("resize", onResizeChangeVideosHeight);
		};
	}, [streams]);

	const getProviderName = (provider) => {
		switch (provider) {
			case GAME_STREAM_CONFIGURATION_TYPE.WOWZA:
				return t("pages.dashboard.games.streamConfigs.wowza");
			case GAME_STREAM_CONFIGURATION_TYPE.NANOCOSMOS:
				return t("pages.dashboard.games.streamConfigs.nanocosmos");
			case GAME_STREAM_CONFIGURATION_TYPE.FLUSSONIC:
				return t("pages.dashboard.games.streamConfigs.flussonic");
			case GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER:
				return t("pages.dashboard.games.streamConfigs.ovenplayer");
			default:
				return " - ";
		}
	};

	return (
		<Fragment>
			<div className="dashboard-section table-section">
				<Breadcrumbs items={[{ title: t("pages.dashboard.developerSpaceStreams.streams") }]} />
				<Spin spinning={isLoading} wrapperClassName="form-spin">
					<div className="dashboard-section-content">
						<Row gutter={[40, 0]}>
							{Array.isArray(streams)
								? streams.map((stream, i) => {
										return (
											<Col
												xs={24}
												sm={12}
												xl={6}
												key={stream.id}
												style={{
													marginBottom: "1.5rem"
												}}
											>
												<Row>
													<Col span={24}>
														<h1
															title={`${t("pages.dashboard.developerSpaceStreams.gameId")}: ${stream.id}`}
															style={{
																marginBottom: "0.5rem"
															}}
														>
															{stream.id}
														</h1>
													</Col>
												</Row>
												<Row>
													<Col span={24}>
														<span
															style={{
																display: "inline-block",
																marginBottom: "0.5rem"
															}}
															title={`${t("pages.dashboard.developerSpaceStreams.gameName")}: ${stream.name}`}
														>
															{stream.name}
														</span>
													</Col>
												</Row>
												<Row>
													<Col span={24}>
														<span
															style={{
																display: "inline-block",
																marginBottom: "0.5rem"
															}}
															title={`${t("pages.dashboard.developerSpaceStreams.streamProvider")}: ${getProviderName(stream.streamProvider)}`}
														>
															{getProviderName(stream.streamProvider)}
														</span>
													</Col>
												</Row>
												<Row>
													<Col span={24}>
														{stream.streamProvider !== null && (
															<VideoPlayer
																id={stream.id}
																streamConfiguration={
																	stream.streamProvider !== GAME_STREAM_CONFIGURATION_TYPE.WOWZA
																		? stream.streamConfiguration
																		: {
																				...stream.streamConfiguration,
																				streamUrl: stream.streamConfiguration.streamUrl ? stream.streamConfiguration.streamUrl.replace("[LANG]", "ru") : stream.streamConfiguration.streamUrl
																			}
																}
																streamProvider={stream.streamProvider}
																showFullscreen={true}
															/>
														)}
													</Col>
												</Row>
											</Col>
										);
									})
								: null}
						</Row>
					</div>
				</Spin>
			</div>
		</Fragment>
	);
};

/** StreamsComponent propTypes
 * PropTypes
 */
StreamsComponent.propTypes = {
	streams: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool,
	getStreams: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getStreams: () => {
		dispatch(getStreams());
	}
});

const mapStateToProps = (state) => {
	return {
		streams: state.developerSpaceStreams.streams,
		isLoading: state.developerSpaceStreams.isLoading
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StreamsComponent);
