const Ball = ({ number, color = "red" }) => {
	return (
		<div className="vs--lucky-six-ball-container">
			<div
				key={number}
				className={`vs--lucky-six-ball vs--flex vs--justify-center vs--align-center vs--lucky-six-ball-${color} "vs--lucky-six-ball-border-big"`}
			>
				<span className="vs--lucky-six-ball-number">{number}</span>

				<svg className="vs--lucky-six-ball-shadow" xmlns="http://www.w3.org/2000/svg" viewBox="1.1 0 40 40" fill="none">
					<path
						opacity="0.2"
						d="M40.81 17.3174C40.81 17.3174 32.3447 15.5341 25.0199 11.2755C17.6951 7.01692 11.5901 2.4491 11.5901 2.4491C14.0902 0.782477 22.5194 -2.2336 31.0888 2.7486C38.4136 7.00717 40.5834 14.1662 40.81 17.3174Z"
						fill="url(#paint0_linear_9546_1627)"
					/>
					<defs>
						<linearGradient id="paint0_linear_9546_1627" x1="29.3707" y1="3.5376" x2="25.1049" y2="11.2375" gradientUnits="userSpaceOnUse">
							<stop stopColor="white" />
							<stop offset="1" stopColor="white" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>

				<svg className="vs--lucky-six-ball-shadow-hover" xmlns="http://www.w3.org/2000/svg" viewBox="0.5 0.5 47 47" fill="none">
					<g filter="url(#filter0_ii_16365_169747)">
						<rect x="0.5" y="0.5" width="47" height="47" rx="23.5" fill="url(#paint0_linear_16365_169747)" fillOpacity="0.5" stroke="" />
					</g>
					<defs>
						<filter
							id="filter0_ii_16365_169747"
							x="-2"
							y="-2"
							width="52"
							height="52"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="-2" dy="2" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="shape" result="effect1_innerShadow_16365_169747" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset dx="2" dy="-2" />
							<feGaussianBlur stdDeviation="1" />
							<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
							<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
							<feBlend mode="normal" in2="effect1_innerShadow_16365_169747" result="effect2_innerShadow_16365_169747" />
						</filter>
						<linearGradient id="paint0_linear_16365_169747" x1="24" y1="0" x2="24" y2="48" gradientUnits="userSpaceOnUse">
							<stop stopColor="#525252" />
							<stop offset="0.61" stopColor="#292929" />
						</linearGradient>
					</defs>
				</svg>
			</div>
		</div>
	);
};

export default Ball;
