import { useTranslation } from "react-i18next";
import { Button, Row } from "antd";

const FooterButtons = ({ onClose, onSave, saveButtonProps }) => {
	const { t } = useTranslation();

	return (
		<Row className="ant-modal-footer" justify="end" style={{ padding: 10 }}>
			<Button onClick={onClose} className="ant-btn">
				{t("common.cancel")}
			</Button>
			<Button onClick={onSave} {...saveButtonProps} className="ant-btn ant-btn-primary">
				{t("common.save")}
			</Button>
		</Row>
	);
};

export default FooterButtons;
