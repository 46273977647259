import SSBTComponent from "components/dashboard/ssbt/ssbt.component.jsx";
import withPermission from "hocs/withPermission.jsx";
import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants.js";
import Paths from "constants/path.constants.js";

const SSBTRoute = () => {
	return (
		<>
			<SSBTComponent />
		</>
	);
};

export default withPermission(SSBTRoute, { resource: PERMISSION_RESOURCE.TERMINAL, action: PERMISSION_ACTION.VIEW }, Paths.DASHBOARD);
