import { combineReducers } from "redux";

import AuthReducer from "./auth/auth.reducer";
import PermissionReducer from "./dashboard/permission.reducer";
import PermissionGroupsReducer from "./dashboard/permissionGroups.reducer";
import RiskManagementReducer from "./dashboard/riskManagement.reducer";
import UsersReducer from "./dashboard/users.reducer";
import AccessManagersReducer from "./dashboard/accessManagers.reducer";
import PermissionRequestsReducer from "./dashboard/permissionRequests.reducer";
import CashiersReducer from "./dashboard/cashiers.reducer";
import ProfileReducer from "./dashboard/profile.reducer";
import NotificationsReducer from "./dashboard/notifications.reducer";
import PartnersReducer from "./dashboard/partners.reducer";
import PartnerReducer from "./dashboard/partner.reducer";
import ApikeysReducer from "./dashboard/apikeys.reducer";
import SSBTsReducer from "./dashboard/ssbt.reducer";
import BetslipsReducer from "./dashboard/betslips.reducer";
import EventsReducer from "./dashboard/events.reducer";
import PlayersReducer from "./dashboard/players.reducer";
import ErrorsReducer from "./dashboard/errors.reducer";
import RequestsReducer from "./dashboard/requests.reducer";
import JobsReducer from "./dashboard/jobs.reducer";
import MonitoringReducer from "./dashboard/monitoring.reducer";
import DeveloperSpaceStreamsReducer from "./dashboard/developerSpaceStreams.reducer";
import StreamsReducer from "./dashboard/streams.reducer";
import StreamServersReducer from "./dashboard/streamServers.reducer";
import PostDeploymentActionsReducer from "./dashboard/postDeploymentActions.reducer";
import GamesReducer from "./dashboard/games.reducer";
import BoxesReducer from "./dashboard/boxes.reducer";
import BetshopsReducer from "./dashboard/betshops.reducer";
import BonusesReducer from "./dashboard/bonuses.reducer";
import ReportsReducer from "./dashboard/reports.reducer";
import TransactionsReducer from "./dashboard/transactions.reducer";
import TranslationReducer from "./dashboard/translation.reducer";
import TicketsReducer from "./dashboard/tickets.reducer";
import HotkeysReducer from "./dashboard/hotkeys.reducer";
import UserLogsReducer from "./dashboard/userLogs.reducer";
import MaintenanceModeReducer from "./dashboard/maintenanceMode.reducer";
import SystemCurrenciesReducer from "./dashboard/systemCurrencies.reducer";
import SystemLanguagesReducer from "./dashboard/systemLanguages.reducer";
import OperatorsReducer from "./dashboard/operators.reducer";
import DashboardReducer from "./dashboard/dashboard.reducer";
import AutoSuggestionReducer from "./dashboard/autoSuggestion.reducer";
import UserSurveysReducer from "./dashboard/userSurveys.reducer";
import SystemReducer from "./system/system.reducer";
import BetslipCorrectionsReducer from "./dashboard/betCorrections.reducer";

export default combineReducers({
	auth: AuthReducer,
	permissions: PermissionReducer,
	permissionGroups: PermissionGroupsReducer,
	riskManagement: RiskManagementReducer,
	users: UsersReducer,
	accessManagers: AccessManagersReducer,
	permissionRequests: PermissionRequestsReducer,
	profile: ProfileReducer,
	notifications: NotificationsReducer,
	partners: PartnersReducer,
	partner: PartnerReducer,
	apikeys: ApikeysReducer,
	ssbts: SSBTsReducer,
	betslips: BetslipsReducer,
	events: EventsReducer,
	players: PlayersReducer,
	errors: ErrorsReducer,
	requests: RequestsReducer,
	jobs: JobsReducer,
	monitoring: MonitoringReducer,
	developerSpaceStreams: DeveloperSpaceStreamsReducer,
	streams: StreamsReducer,
	streamServers: StreamServersReducer,
	postDeploymentActions: PostDeploymentActionsReducer,
	games: GamesReducer,
	boxes: BoxesReducer,
	betshops: BetshopsReducer,
	cashiers: CashiersReducer,
	bonuses: BonusesReducer,
	reports: ReportsReducer,
	transactions: TransactionsReducer,
	translations: TranslationReducer,
	tickets: TicketsReducer,
	hotkeys: HotkeysReducer,
	userLogs: UserLogsReducer,
	maintenanceMode: MaintenanceModeReducer,
	systemCurrencies: SystemCurrenciesReducer,
	systemLanguages: SystemLanguagesReducer,
	operators: OperatorsReducer,
	dashboard: DashboardReducer,
	autoSuggestion: AutoSuggestionReducer,
	surveys: UserSurveysReducer,
	system: SystemReducer,
	betCorrections: BetslipCorrectionsReducer
});
