import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getApiKeyGameOtherConfigs, saveApiKeyGameOtherConfigs } from "store/actions/dashboard/online/apikeys/gameOtherConfigs.action";
import { Form, Checkbox, Space, Spin } from "antd";
const { Item: FormItem } = Form;
import FooterButtons from "./footerButtons";
import { flagsToBinary, binaryToFlags } from "utils/common";
import { isFormChanged } from "utils/form";
import { hasPermission } from "utils/permissions";
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { GAME_LABEL_TYPE_FLAGS, GAME_CATEGORY } from "constants/game.constants";

/** Apikey Game RTP managment Popup Component */
const OtherConfigs = ({
	gameLabel,
	isLoading,
	isSaving,
	onClose,
	gameId,
	apiKeyId,
	getApiKeyGameOtherConfigs,
	saveApiKeyGameOtherConfigs,
	gameCategory
}) => {
	const initialValues = {
		gameLabel: []
	};

	const [form] = Form.useForm();
	const [isFormTouched, setIsFormTouched] = useState(false);
	const isDisabled = !hasPermission({ resource: PERMISSION_RESOURCE.APIKEY_OTHER_CONFIGS, action: PERMISSION_ACTION.MODIFY });

	/** Fires when form submitted
	 * @function
	 * @memberOf OtherConfigs
	 */
	const onFinish = ({ gameLabel }) => {
		const submitValues = { gameId, id: apiKeyId };
		if (flagsToBinary(gameLabel)) submitValues["gameLabel"] = flagsToBinary(gameLabel);

		saveApiKeyGameOtherConfigs({
			data: submitValues,
			gameCategory,
			callback: () => {
				onClose(null);
			}
		});
	};

	const saveButtonProps = {
		loading: isSaving,
		disabled: isDisabled || !isFormTouched,
		style: isDisabled ? { display: "none" } : {}
	};

	/** Form fields change handler, for updating local state data */
	const onFieldsChange = (_, allFields) => {
		const changedData = allFields.reduce((data, changedField) => {
			const name = changedField?.name?.[0];
			const value = changedField?.value;

			data[name] = value.toString();
			return data;
		}, {});

		/** Activate save button only when there is a change */
		setIsFormTouched(
			isFormChanged(
				{
					gameLabel: Array.isArray(gameLabel) ? gameLabel.toString() : gameLabel
				},
				changedData
			)
		);
	};

	/** Listen for other config data get success */
	useEffect(() => {
		if (gameLabel) {
			form.setFieldsValue({
				gameLabel: binaryToFlags(GAME_LABEL_TYPE_FLAGS, gameLabel)
			});
		}

		return () => {
			form.setFieldsValue(initialValues);
		};
	}, [gameLabel]);

	/** Activate save button only when there is a change */
	useEffect(() => {
		getApiKeyGameOtherConfigs({ gameId, gameCategory, id: apiKeyId });
	}, []);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Form
				form={form}
				onFinish={onFinish}
				onFieldsChange={onFieldsChange}
				className="dashboard-form"
				colon={false}
				requiredMark={false}
				layout="vertical"
				initialValues={initialValues}
			>
				<FormItem name="gameLabel">
					<Checkbox.Group>
						<Checkbox value={1} style={{ lineHeight: "32px" }} disabled={isDisabled}>
							New Game
						</Checkbox>
						<Checkbox value={2} style={{ lineHeight: "32px", marginLeft: "11px" }} disabled={isDisabled}>
							Is Test
						</Checkbox>
					</Checkbox.Group>
				</FormItem>
			</Form>
			<FooterButtons onClose={onClose} onSave={form.submit} saveButtonProps={saveButtonProps} />
		</Space>
	);
};

OtherConfigs.propTypes = {
	/** Redux state property, is true when rtps of api key game is saving */
	isSaving: PropTypes.bool,
	/** Redux state property, is true when api key game is loading */
	isLoading: PropTypes.bool,
	/** Fires on popup close */
	onClose: PropTypes.func,
	/** Api keys Current editing game other configs label */
	gameLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Api keys Current editing game id */
	gameId: PropTypes.string,
	/** Redux state, Id of current editing apiKey */
	apiKeyId: PropTypes.string,
	/** Redux action to get game other configs of api key game */
	getApiKeyGameOtherConfigs: PropTypes.func,
	/** Redux action to save game other configs of api key game */
	saveApiKeyGameOtherConfigs: PropTypes.func,
	/** React property, game category */
	gameCategory: PropTypes.oneOf(Object.values(GAME_CATEGORY))
};

const mapDispatchToProps = (dispatch) => ({
	getApiKeyGameOtherConfigs: (obj) => {
		dispatch(getApiKeyGameOtherConfigs(obj));
	},
	saveApiKeyGameOtherConfigs: (obj) => {
		dispatch(saveApiKeyGameOtherConfigs(obj));
	}
});

const mapStateToProps = (state) => {
	return {
		gameLabel: state.apikeys.editingApikey.otherConfigs.data.gameLabel,
		isLoading: state.apikeys.editingApikey.otherConfigs.isLoading,
		isSaving: state.apikeys.isSaving,
		apiKeyId: state.apikeys.editingApikey.generalInfo.id
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherConfigs);
