import { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Form, Button, Col, Row } from "antd";
const { Item: FormItem } = Form;

import CommonRTP from "./commonRTP";
import KenoRTP from "./kenoRTP";
import LuckySixRTP from "./luckySixRTP";

import { hasPermission } from "utils/permissions";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

import { getRTPs, saveRTPs, resetRTPs } from "store/actions/dashboard/virtuals/games/rtp.action";

import { INSTANT_GAME_TYPE, SCHEDULED_GAME_TYPE } from "constants/game.constants";

import rtpType from "types/rtp/rtp.type";
import { PERIOD } from "constants/market.constants";

/** Game Edit Page Default RTP Tab Component */
const RTPComponent = ({ isSaving, isLoading, getRTPs, saveRTPs, resetRTPs, rtp, gameType, onTabChange }) => {
	const { t } = useTranslation();
	const [formInstance] = Form.useForm();
	const { validateFields, setFieldsValue } = formInstance;
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [orders, setOrders] = useState({});
	const [currentValues, setCurrentValues] = useState({});
	const containerRef = useRef(null);

	/** Set form fields values, when data is loaded */
	useEffect(() => {
		const orders = {};
		rtp.rtPs.forEach((r) => {
			orders[r.name] = r.orderNumber;
		});
		setOrders(orders);

		const values = {
			rtPs: Object.fromEntries(
				rtp.rtPs.map((r, i) => [
					r.name + "_" + r.period,
					{
						margin: r.margin,
						rtp: r.rtp,
						enabled: r.enabled
					}
				])
			)
		};

		setFieldsValue(values);
		setCurrentValues(values);
	}, [rtp.rtPs]);

	/** reset Rtp data when exiting from page */
	useEffect(() => () => resetRTPs(), []);

	/** Fires when form submitted
	 * @function
	 * @memberOf RTPComponent
	 */
	const handleForm = () => {
		validateFields()
			.then((values) => {
				const d = {
					rtPs: rtp.rtPs.reduce((acc, d) => {
						if (d.margin <= 0) {
							return acc;
						}
						const rtpObj = values.rtPs[d.name + "_" + d.period];
						acc.push({
							margin: rtpObj ? rtpObj.margin : d.margin,
							enabled:
								rtpObj && ![SCHEDULED_GAME_TYPE.KENO.value, SCHEDULED_GAME_TYPE.LUCKY_SIX.value].includes(gameType)
									? rtpObj.enabled
									: d.enabled,
							name: d.name,
							period: d.period,
							orderNumber: orders[d.name]
						});
						return acc;
					}, [])
				};
				saveRTPs(d);
				setIsFormTouched(false);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};

	useEffect(() => {
		onTabChange && onTabChange(isFormTouched);
	}, [isFormTouched]);

	return (
		<Form
			colon={false}
			form={formInstance}
			requiredMark={false}
			layout="vertical"
			onValuesChange={(_, values) => {
				if (![SCHEDULED_GAME_TYPE.KENO.value,  SCHEDULED_GAME_TYPE.LUCKY_SIX.value].includes(gameType)) {
					const rtPsEntries = Object.entries(values.rtPs);
					const filtered = rtPsEntries.filter(([key, value]) => {
						const periodStr = key.at(-1);
						return PERIOD.MATCH.toString() === periodStr && !value.enabled;
					});
					if (filtered.length > 0) {
						const newValues = { ...values, rtPs: { ...values.rtPs } };
						filtered.forEach((entry) => {
							const [groupName] = entry.at(0).split("_");
							[`${groupName}_${PERIOD.FIRSTHALF}`, `${groupName}_${PERIOD.SECONDHALF}`].forEach((subGroup) => {
								if (newValues.rtPs[subGroup]) {
									newValues.rtPs[subGroup] = {
										...newValues.rtPs[subGroup],
										enabled: false
									};
									formInstance.setFieldValue(["rtPs", subGroup, "enabled"], false);
								}
							});
						});
						setCurrentValues(newValues);
					} else {
						setCurrentValues(values);
					}
				} else {
					setCurrentValues(values);
				}
				setIsFormTouched(true);
			}}
		>
			<div ref={containerRef} className="dashboard-section-content" id="games_rtps_container">
				<Row>
					<Col span={24}>
						{(() => {
							if (!gameType) {
								return <Fragment />;
							}
							const commonProps = {
								isLoading,
								rtp,
								getRTPs,
								setIsFormTouched,
								formInstance
							};
							if (gameType === SCHEDULED_GAME_TYPE.KENO.value) {
								return <KenoRTP {...commonProps} currentValues={currentValues} />;
							}

							if (gameType === SCHEDULED_GAME_TYPE.LUCKY_SIX.value) {
								return <LuckySixRTP {...commonProps} />;
							}

							return <CommonRTP {...commonProps} gameType={gameType} orders={orders} setOrders={setOrders} containerRef={containerRef} />;
						})()}
					</Col>
				</Row>

				{hasPermission({ resource: PERMISSION_RESOURCE.GAME_RTP, action: PERMISSION_ACTION.MODIFY }) && (
					<FormItem className="button-container">
						<Button loading={isSaving} type="primary" htmlType="submit" className="button" onClick={handleForm} disabled={!isFormTouched}>
							<span>{t("common.save")}</span>
						</Button>
					</FormItem>
				)}
			</div>
		</Form>
	);
};

/** RTPComponent propTypes
 * PropTypes
 */
RTPComponent.propTypes = {
	/** Is true when rtps is saving */
	isSaving: PropTypes.bool,
	/** Is true when rtps are loading */
	isLoading: PropTypes.bool,
	/** Function to get rtps */
	getRTPs: PropTypes.func,
	/** Function to save rtps */
	saveRTPs: PropTypes.func,
	/** Function to clear rtps */
	resetRTPs: PropTypes.func,
	/** Redux state, represents the rtp data   */
	rtp: rtpType,
	/** Current game type */
	gameType: PropTypes.oneOf([...Object.values(SCHEDULED_GAME_TYPE), ...Object.values(INSTANT_GAME_TYPE)].map((v) => v.value)),
	/** Fires when form saved/unsaved state is changed */
	onTabChange: PropTypes.func
};

export default RTPComponent;
